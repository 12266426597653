import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { Space } from '../../../common/components/Space';
import { getFriendsList } from '../friendsReducer';
import { AddFriend } from './AddFriend';
import { FriendList } from './FriendList';

interface FriendsPageProps {}

const StyledFriendsPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAddFriend = styled.div`
  align-self: flex-end;
`;

export const FriendsPage: React.FC<FriendsPageProps> = () => {
  const [editing, setEditing] = useState(false);

  const friends = useSelector(getFriendsList);

  return (
    <StyledFriendsPage>
      {friends.length > 0 && (
        <StyledAddFriend onClick={() => setEditing(editing => !editing)}>
          {editing ? (
            <Icon color={'green'} name={'check'} circular={true} link={true} />
          ) : (
            <Icon name={'edit'} circular={true} link={true} />
          )}
        </StyledAddFriend>
      )}
      <FriendList editing={editing} friends={friends} />
      <Space num={2} />
      <StyledAddFriend>
        <AddFriend />
      </StyledAddFriend>
    </StyledFriendsPage>
  );
};
