import * as React from 'react';
import { Icon } from 'semantic-ui-react';

interface AddButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  label: string;
}

export const AddButton: React.FC<AddButtonProps> = ({
  disabled,
  onClick,
  label,
}) => {
  return (
    <div onClick={onClick}>
      <Icon
        circular={true}
        color={disabled ? undefined : 'green'}
        disabled={disabled}
        link={!disabled}
        name={'plus'}
      />
    </div>
  );
};
