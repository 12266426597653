import { groupBy, orderBy } from 'lodash';
import { Recipe, RecipeTypeEnum } from '../types/Recipe';

export interface RecipeOnCourseType {
  title: string;
  type?: RecipeTypeEnum;
  recipes: Recipe[];
}

export const splitRecipesOnCourseType = (
  recipes: Recipe[]
): RecipeOnCourseType[] => {
  const recipesOnCourseType = groupBy(recipes, 'type');
  const retVal: RecipeOnCourseType[] = [];
  if (recipesOnCourseType[RecipeTypeEnum.Appetizer]) {
    retVal.push({
      title: RecipeTypeEnum.Appetizer,
      type: RecipeTypeEnum.Appetizer,
      recipes: orderBy(recipesOnCourseType[RecipeTypeEnum.Appetizer], 'name'),
    });
  }
  if (recipesOnCourseType[RecipeTypeEnum.MainCourse]) {
    retVal.push({
      title: RecipeTypeEnum.MainCourse,
      type: RecipeTypeEnum.MainCourse,
      recipes: orderBy(recipesOnCourseType[RecipeTypeEnum.MainCourse], 'name'),
    });
  }
  if (recipesOnCourseType[RecipeTypeEnum.Dessert]) {
    retVal.push({
      title: RecipeTypeEnum.Dessert,
      type: RecipeTypeEnum.Dessert,
      recipes: orderBy(recipesOnCourseType[RecipeTypeEnum.Dessert], 'name'),
    });
  }
  if (recipesOnCourseType['undefined']) {
    retVal.push({
      title: 'No type',
      recipes: orderBy(recipesOnCourseType['undefined'], 'name'),
    });
  }
  return retVal;
};
