import uuid from 'uuid';
import { Omit } from '../../common/types/helpers/Omit';
import { Ingredient } from '../../common/types/Ingredient';
import { Recipe, RecipeTypeEnum } from '../../common/types/Recipe';
import { RecipesReducerState } from './RecipesReducer';

export interface SetInitialAction {
  payload: {
    initial: RecipesReducerState;
  };
  type: 'RECIPES:SET_INITIAL';
}

export const setInitial = (initial: RecipesReducerState): SetInitialAction => ({
  payload: {
    initial,
  },
  type: 'RECIPES:SET_INITIAL',
});

interface AddRecipe {
  type: 'RECIPES:ADD_RECIPE';
  recipe: Recipe;
}

export const addRecipe = (
  recipe: Partial<Omit<Recipe, 'id' | 'name'>> & Pick<Recipe, 'name'>,
  id = uuid.v4()
): AddRecipe => ({
  type: 'RECIPES:ADD_RECIPE',
  recipe: {
    ingredients: [],
    specialCostIds: [],
    ...recipe,
    id,
  },
});

interface RemoveRecipe {
  type: 'RECIPES:REMOVE_RECIPE';
  recipeId: string;
}

export const removeRecipe = (recipeId: string): RemoveRecipe => ({
  type: 'RECIPES:REMOVE_RECIPE',
  recipeId,
});

interface EditName {
  type: 'RECIPES:EDIT_NAME';
  recipeId: string;
  name: string;
}

export const editName = (recipeId: string, name: string): EditName => ({
  type: 'RECIPES:EDIT_NAME',
  recipeId,
  name,
});

interface EditTime {
  type: 'RECIPES:EDIT_TIME';
  recipeId: string;
  time: string;
}

export const editTime = (recipeId: string, time: string): EditTime => ({
  type: 'RECIPES:EDIT_TIME',
  recipeId,
  time,
});

interface EditType {
  type: 'RECIPES:EDIT_TYPE';
  recipeId: string;
  recipeType: RecipeTypeEnum;
}

export const editType = (
  recipeId: string,
  recipeType: RecipeTypeEnum
): EditType => ({
  type: 'RECIPES:EDIT_TYPE',
  recipeId,
  recipeType,
});

interface EditAbout {
  type: 'RECIPES:EDIT_ABOUT';
  recipeId: string;
  about: string;
}

export const editAbout = (recipeId: string, about: string): EditAbout => ({
  type: 'RECIPES:EDIT_ABOUT',
  recipeId,
  about,
});

interface EditInstructions {
  type: 'RECIPES:EDIT_INSTRUCTIONS';
  recipeId: string;
  instructions: string;
}

export const editInstructions = (
  recipeId: string,
  instructions: string
): EditInstructions => ({
  type: 'RECIPES:EDIT_INSTRUCTIONS',
  recipeId,
  instructions,
});

interface EditIngredient {
  type: 'RECIPES:EDIT_INGREDIENT';
  recipeId: string;
  index: number;
  ingredient: Ingredient;
}

export const editIngredient = (
  recipeId: string,
  index: number,
  ingredient: Ingredient
): EditIngredient => ({
  type: 'RECIPES:EDIT_INGREDIENT',
  recipeId,
  index,
  ingredient,
});

interface EditLink {
  type: 'RECIPES:EDIT_LINK';
  recipeId: string;
  link: string;
}

export const editLink = (recipeId: string, link: string): EditLink => ({
  type: 'RECIPES:EDIT_LINK',
  recipeId,
  link,
});

interface EditPortions {
  type: 'RECIPES:EDIT_PORTIONS';
  recipeId: string;
  portions: string;
}

export const editPortions = (
  recipeId: string,
  portions: string
): EditPortions => ({
  type: 'RECIPES:EDIT_PORTIONS',
  recipeId,
  portions,
});

interface AddIngredient {
  type: 'RECIPES:ADD_INGREDIENT';
  recipeId: string;
  ingredient: Ingredient;
}

export const addIngredient = (
  recipeId: string,
  ingredient: Ingredient
): AddIngredient => ({
  type: 'RECIPES:ADD_INGREDIENT',
  recipeId,
  ingredient,
});

interface RemoveIngredient {
  type: 'RECIPES:REMOVE_INGREDIENT';
  recipeId: string;
  index: number;
}

export const removeIngredient = (
  recipeId: string,
  index: number
): RemoveIngredient => ({
  type: 'RECIPES:REMOVE_INGREDIENT',
  recipeId,
  index,
});

interface AddSpecialCost {
  type: 'RECIPES:ADD_SPECIAL_COST';
  recipeId: string;
  specialCostId: string;
}

export const addSpecialCost = (
  recipeId: string,
  specialCostId: string
): AddSpecialCost => ({
  type: 'RECIPES:ADD_SPECIAL_COST',
  recipeId,
  specialCostId,
});

interface RemoveSpecialCost {
  type: 'RECIPES:REMOVE_SPECIAL_COST';
  recipeId: string;
  specialCostId: string;
}

export const removeSpecialCost = (
  recipeId: string,
  specialCostId: string
): RemoveSpecialCost => ({
  type: 'RECIPES:REMOVE_SPECIAL_COST',
  recipeId,
  specialCostId,
});

export type RecipesActions = AddRecipe | RemoveRecipe | SetInitialAction;

export type RecipeActions =
  | AddIngredient
  | AddSpecialCost
  | EditAbout
  | EditName
  | EditTime
  | EditType
  | EditInstructions
  | EditIngredient
  | EditLink
  | EditPortions
  | RemoveIngredient
  | RemoveSpecialCost;
