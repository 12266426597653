import { orderBy } from 'lodash';
import { Friend } from '../types/Friend';

export const sortFriends = (friends: Friend[]): Friend[] => {
  return orderBy(
    friends,
    friend =>
      friend.name
        .split(' ')
        .reverse()
        .join(' '),
    'asc'
  );
};
