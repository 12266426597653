import * as React from 'react';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { Ingredient } from '../../../common/types/Ingredient';

export interface AddIngredientProps {
  addIngredient: (ingredient: Ingredient) => void;
}

export const AddIngredient: React.FC<AddIngredientProps> = ({
  addIngredient,
}) => {
  const onAddIngredient = (name: string) => {
    addIngredient({ name });
  };

  return (
    <AddEntity
      label={'Ingredient name'}
      onAdd={onAddIngredient}
      placeholder={'New Ingredient'}
    />
  );
};
