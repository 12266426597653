import styled from '@emotion/styled';
import * as firebase from 'firebase';
import * as React from 'react';
import { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Store } from 'redux';
import { Container, Loader } from 'semantic-ui-react';
import { AppWithDatabase } from './AppWithDatabase';
import {
  setSessionFetched,
  userLoggedIn,
  userLoggedOut,
} from './auth/authActions';
import {
  isAuthenticated,
  isSessionInformationFetched,
} from './auth/authReducer';
import { Header } from './page/Header';
import { StoreState } from './rootReducer';

const mapState = (state: StoreState) => ({
  authenticated: isAuthenticated(state),
  sessionFetched: isSessionInformationFetched(state),
});

const LoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface AppWithLoginProps {
  store: Store;
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const config = {
  apiKey: 'AIzaSyCxAcExrT98Jxh0RXW1Ts3ki3YKfKUaGjs',
  appId: '1:943034902799:web:1dc40cc9f61599c557f67a',
  authDomain: 'visitbook-d1b02.firebaseapp.com',
  databaseURL: 'https://visitbook-d1b02.firebaseio.com',
  projectId: 'visitbook-d1b02',
  storageBucket: 'visitbook-d1b02.appspot.com',
  messagingSenderId: '943034902799',
};

export const AppWithLogin: React.FC<AppWithLoginProps> = ({ store }) => {
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    store.dispatch(setSessionFetched(undefined));
  }, [store]);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user !== null) {
        store.dispatch(
          userLoggedIn({ email: user.email || '', uid: user.uid })
        );
      } else {
        store.dispatch(userLoggedOut());
      }
    });
    return () => {
      unregisterAuthObserver();
    };
  }, [store]);

  const logout = () => {
    return firebase.auth().signOut();
  };

  const { authenticated, sessionFetched } = useSelector(mapState);

  return (
    <Switch>
      <Route>
        <>
          <Header
            authenticated={authenticated}
            loading={sessionFetched}
            logout={logout}
          />
          <main>
            <Container style={{ marginTop: '79px' }}>
              {!sessionFetched ? (
                <LoadingWrapper>
                  <Loader active inline />
                </LoadingWrapper>
              ) : !authenticated ? (
                <div>
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </div>
              ) : (
                <AppWithDatabase store={store} />
              )}
            </Container>
          </main>
        </>
      </Route>
    </Switch>
  );
};
