import * as React from 'react';
import { TextArea } from '../../../common/components/TextArea';

interface AboutProps {
  about?: string;
  edit: (text: string) => void;
}

export const About: React.FC<AboutProps> = ({ about, edit }) => {
  return <TextArea value={about} onChange={edit} label="About" />;
};
