import { Recipe } from '../../common/types/Recipe';
import { StoreState } from '../../rootReducer';
import { recipeReducer } from './RecipeReducer';
import { RecipeActions, RecipesActions } from './RecipesActions';

export interface RecipesReducerState {
  list: Recipe[];
}

export const INITIAL_STATE: RecipesReducerState = {
  list: [],
};

export const recipesReducer = (
  state = INITIAL_STATE,
  action: RecipesActions | RecipeActions
): RecipesReducerState => {
  switch (action.type) {
    case 'RECIPES:SET_INITIAL':
      return {
        ...action.payload.initial,
        list: action.payload.initial.list.map(recipe => ({
          ...recipe,
          ingredients: recipe.ingredients || [],
          specialCostIds: recipe.specialCostIds || [],
        })),
      };
    case 'RECIPES:ADD_RECIPE':
      return {
        ...state,
        list: [...state.list, action.recipe],
      };
    case 'RECIPES:REMOVE_RECIPE':
      return {
        ...state,
        list: state.list.filter(recipe => recipe.id !== action.recipeId),
      };
    case 'RECIPES:EDIT_ABOUT':
    case 'RECIPES:EDIT_NAME':
    case 'RECIPES:EDIT_TIME':
    case 'RECIPES:EDIT_TYPE':
    case 'RECIPES:EDIT_INGREDIENT':
    case 'RECIPES:EDIT_INSTRUCTIONS':
    case 'RECIPES:EDIT_LINK':
    case 'RECIPES:EDIT_PORTIONS':
    case 'RECIPES:ADD_INGREDIENT':
    case 'RECIPES:REMOVE_INGREDIENT':
    case 'RECIPES:ADD_SPECIAL_COST':
    case 'RECIPES:REMOVE_SPECIAL_COST':
      return {
        ...state,
        list: state.list.map(recipe => {
          if (recipe.id === action.recipeId) {
            return recipeReducer(recipe, action);
          }
          return recipe;
        }),
      };
    default:
      return state;
  }
};

export const getRecipesState = (state: StoreState): RecipesReducerState =>
  state.recipes;
export const getRecipeList = (state: StoreState): Recipe[] =>
  getRecipesState(state).list;
export const getRecipe = (state: StoreState, id: string): Recipe | undefined =>
  getRecipesState(state).list.find(recipe => recipe.id === id);

export const getRecipes = (
  state: StoreState,
  ids: string[]
): Array<Recipe | undefined> => {
  return ids.map(id => getRecipe(state, id));
};
