import * as React from 'react';
import { Responsive } from 'semantic-ui-react';
import { NavBarDesktop } from './NavBarDesktop';
import { NavBarMobile } from './NavBarMobile';

export interface HeaderProps {
  logout: () => Promise<void>;
  authenticated: boolean;
  loading: boolean;
}

export const Header: React.FC<HeaderProps> = ({ authenticated, logout }) => {
  return (
    <>
      <Responsive {...Responsive.onlyMobile}>
        <NavBarMobile logout={logout} authenticated={authenticated} />
      </Responsive>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <NavBarDesktop logout={logout} authenticated={authenticated} />
      </Responsive>
    </>
  );
};
