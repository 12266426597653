import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { TextArea } from '../../../common/components/TextArea';
import { TextInput } from '../../../common/components/TextInput';
import { Wrap } from '../../../common/components/Wrap';
import { FriendGroup as FriendGroupType } from '../../../common/types/FriendGroup';
import {
  addFriendGroup as addFriendGroupAction,
  removeFriendGroup as removeFriendGroupAction,
} from '../../friends/friendsActions';
import { getFriendsList } from '../../friends/friendsReducer';
import { editName, editNote, removeFriendGroup } from '../friendGroupsActions';
import { Friends } from './Friends';

interface FriendGroupProps {
  friendGroup: FriendGroupType;
  editing: boolean;
}

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledName = styled.div`
  font-size: 20px;
`;

const StyledNote = styled.div`
  width: 180px;
  word-break: break-word;
  hyphens: auto;
`;

export const FriendGroup: React.FC<FriendGroupProps> = ({
  editing,
  friendGroup: { id, name, note },
}) => {
  const dispatch = useDispatch();

  const updateName = useCallback(
    input => {
      dispatch(editName(id, input));
    },
    [dispatch, id]
  );

  const updateNote = useCallback(
    input => {
      dispatch(editNote(id, input));
    },
    [dispatch, id]
  );

  const onRemoveFriendGroup = useCallback(() => {
    dispatch(removeFriendGroup(id));
  }, [dispatch, id]);

  const friends = useSelector(getFriendsList);

  const friendsInGroup = friends.filter(friend =>
    friend.friendGroupIds.includes(id)
  );

  const addFriend = useCallback(
    (friendId: string) => {
      dispatch(addFriendGroupAction(friendId, id));
    },
    [dispatch, id]
  );

  const removeFriend = useCallback(
    (friendId: string) => {
      dispatch(removeFriendGroupAction(friendId, id));
    },
    [dispatch, id]
  );

  return (
    <Wrap condition={editing} wrap={children => <Form>{children}</Form>}>
      <div>
        <StyledLabel>
          {editing ? (
            <TextInput
              disabled={!editing}
              label={'Name'}
              onChange={updateName}
              value={name || ''}
            />
          ) : (
            <StyledName>{name}</StyledName>
          )}
          {editing && (
            <>
              <Space />
              <RemoveButton
                label={'Delete group'}
                onClick={onRemoveFriendGroup}
              />
            </>
          )}
        </StyledLabel>
        <Space />
        <Friends
          editing={editing}
          addFriend={addFriend}
          removeFriend={removeFriend}
          friendsInGroup={friendsInGroup}
        />
        <Space />
        <div>
          {editing ? (
            <TextArea
              label={'Notes'}
              onChange={updateNote}
              value={note || ''}
            />
          ) : (
            <StyledNote>{note || ''}</StyledNote>
          )}
        </div>
      </div>
    </Wrap>
  );
};
