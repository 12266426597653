import styled from '@emotion/styled';
import { format, parse } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import { MenuItemProps } from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import { Space } from '../../../common/components/Space';
import { useQuery } from '../../../common/hooks/useQuery';
import { filterIdsFromVisit } from '../utils/filterIdsFromVisit';
import { splitVisitsOnDate } from '../utils/splitVisitsOnDate';
import { getVisitsList } from '../visitsReducer';
import { AddVisit } from './AddVisit';
import { Filter } from './filter/Filter';
import { Visit } from './Visit';

interface VisitsListProps {}

function getDay(date: string) {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'do');
}

const StyledAddVisit = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VisitsList: React.FC<VisitsListProps> = () => {
  const { id } = useParams();
  const query = useQuery();
  const history = useHistory();

  const [editing, setEditing] = useState(false);

  const [activeId, setActiveID] = useState<string | undefined>(id);
  const visits = useSelector(getVisitsList);

  const [filteredGuestIds, setFilteredGuestsIds] = useState<string[]>([]);

  const itemClicked = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { active, id }: MenuItemProps
  ) => {
    if (!active) {
      history.push('/visit/' + id);
    } else {
      history.push('/visits/');
    }
  };

  const onVisitAdded = (id: string) => {
    setActiveID(id);
    setEditing(true);
  };

  useEffect(() => {
    setActiveID(id);
  }, [id]);

  useEffect(() => {
    if (query.get('editing') === 'true') {
      setEditing(true);
    }
  }, [query]);

  const activeVisit = visits.find(visit => visit.id === activeId);

  const filteredVisits = visits.filter(filterIdsFromVisit(filteredGuestIds));
  return (
    <div>
      <Grid stackable={true} columns={2} reversed="computer">
        <Grid.Row stretched>
          <Grid.Column width={12}>
            <Segment>
              {activeVisit ? (
                <Visit
                  editing={editing}
                  setEditing={setEditing}
                  key={activeVisit.id}
                  visit={activeVisit}
                />
              ) : (
                <div>No visit selected. Please pick one.</div>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Menu fluid vertical>
              {filteredVisits.length === 0 && visits.length > 0 && (
                <Menu.Item>Your have filtered to much</Menu.Item>
              )}
              {visits.length === 0 && (
                <Menu.Item>You don't have any visits</Menu.Item>
              )}
              {splitVisitsOnDate(filteredVisits).map((visitForDate, index) => {
                return (
                  <Menu.Item key={visitForDate.title}>
                    <Menu.Header>{visitForDate.title}</Menu.Header>
                    <Menu.Menu>
                      {visitForDate.visits.map(visit => (
                        <Menu.Item
                          key={visit.id}
                          id={visit.id}
                          active={activeId === visit.id}
                          index={index}
                          onClick={itemClicked}
                        >
                          {visit.date
                            ? `${getDay(visit.date)} - ${visit.name}`
                            : visit.name}
                        </Menu.Item>
                      ))}
                    </Menu.Menu>
                  </Menu.Item>
                );
              })}
              <Menu.Item>
                <Filter
                  filteredGuestsIds={filteredGuestIds}
                  onFilteredGuestIds={setFilteredGuestsIds}
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Space num={2} />
      <StyledAddVisit>
        <AddVisit onVisitAdded={onVisitAdded} />
      </StyledAddVisit>
    </div>
  );
};
