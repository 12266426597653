import uuid from 'uuid';
import { Friend } from '../../common/types/Friend';
import { Omit } from '../../common/types/helpers/Omit';
import { FriendsReducerState } from './friendsReducer';

export const ADD_FRIEND = 'ADD_FRIEND';
export const REMOVE_FRIEND = 'REMOVE_FRIEND';
export const SELECT_FRIEND = 'SELECT_FRIEND';

export interface SetInitialAction {
  payload: {
    initial: FriendsReducerState;
  };
  type: 'FRIENDS:SET_INITIAL';
}

export const setInitial = (initial: FriendsReducerState): SetInitialAction => ({
  payload: {
    initial,
  },
  type: 'FRIENDS:SET_INITIAL',
});

interface AddFriendAction {
  friend: Friend;
  type: 'ADD_FRIEND';
}

export const addFriend = (
  friend: Partial<Omit<Friend, 'id' | 'name'>> & Pick<Friend, 'name'>,
  id = uuid.v4()
): AddFriendAction => ({
  type: ADD_FRIEND,
  friend: {
    friendGroupIds: [],
    specialCostIds: [],
    ...friend,
    id,
  },
});

interface RemoveFriendAction {
  id: string;
  type: 'REMOVE_FRIEND';
}

export const removeFriend = (id: string): RemoveFriendAction => ({
  type: 'REMOVE_FRIEND',
  id,
});

interface SelectFriendAction {
  id: string;
  type: 'SELECT_FRIEND';
}

export const selectFriend = (id: string): SelectFriendAction => ({
  type: 'SELECT_FRIEND',
  id,
});

export interface EditNameAction {
  friendId: string;
  name: string;
  type: 'EDIT_FRIEND_NAME';
}

export const editName = (friendId: string, name: string): EditNameAction => ({
  friendId,
  name,
  type: 'EDIT_FRIEND_NAME',
});

export interface EditNoteAction {
  friendId: string;
  note: string;
  type: 'EDIT_FRIEND_NOTE';
}

export const editNote = (friendId: string, note: string): EditNoteAction => ({
  friendId,
  note,
  type: 'EDIT_FRIEND_NOTE',
});

interface AddFriendGroup {
  type: 'FRIEND:ADD_FRIEND_GROUP';
  friendId: string;
  friendGroupId: string;
}

export const addFriendGroup = (
  friendId: string,
  friendGroupId: string
): AddFriendGroup => ({
  type: 'FRIEND:ADD_FRIEND_GROUP',
  friendId,
  friendGroupId,
});

interface RemoveFriendGroup {
  type: 'FRIEND:REMOVE_FRIEND_GROUP';
  friendId: string;
  friendGroupId: string;
}

export const removeFriendGroup = (
  friendId: string,
  friendGroupId: string
): RemoveFriendGroup => ({
  type: 'FRIEND:REMOVE_FRIEND_GROUP',
  friendId,
  friendGroupId,
});

interface AddSpecialCost {
  type: 'FRIEND:ADD_SPECIAL_COST';
  friendId: string;
  specialCostId: string;
}

export const addSpecialCost = (
  friendId: string,
  specialCostId: string
): AddSpecialCost => ({
  type: 'FRIEND:ADD_SPECIAL_COST',
  friendId,
  specialCostId,
});

interface RemoveSpecialCost {
  type: 'FRIEND:REMOVE_SPECIAL_COST';
  friendId: string;
  specialCostId: string;
}

export const removeSpecialCost = (
  friendId: string,
  specialCostId: string
): RemoveSpecialCost => ({
  type: 'FRIEND:REMOVE_SPECIAL_COST',
  friendId,
  specialCostId,
});

export type FriendsActions =
  | AddFriendAction
  | RemoveFriendAction
  | SelectFriendAction
  | EditNoteAction
  | EditNameAction
  | AddFriendGroup
  | RemoveFriendGroup
  | AddSpecialCost
  | RemoveSpecialCost
  | SetInitialAction;
