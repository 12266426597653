import * as React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { addSpecialCost } from '../specialCostsActions';

interface AddSpecialCostProps {
  onSpecialCostAdded?: (id: string) => void;
}

export const AddSpecialCost: React.FC<AddSpecialCostProps> = ({
  onSpecialCostAdded,
}) => {
  const dispatch = useDispatch();
  const onAddSpecialCost = (name: string) => {
    const id = uuid.v4();
    dispatch(addSpecialCost({ name }, id));
    if (onSpecialCostAdded) {
      onSpecialCostAdded(id);
    }
  };

  return (
    <AddEntity
      label={'New special cost'}
      onAdd={onAddSpecialCost}
      placeholder={'Special cost'}
    />
  );
};
