import { Omit } from 'react-router';
import uuid from 'uuid';
import { Visit } from '../../common/types/Visit';
import { VisitsReducerState } from './visitsReducer';

export interface SetInitialAction {
  payload: {
    initial: VisitsReducerState;
  };
  type: 'VISITS:SET_INITIAL';
}

export const setInitial = (initial: VisitsReducerState): SetInitialAction => ({
  payload: {
    initial,
  },
  type: 'VISITS:SET_INITIAL',
});

interface AddVisit {
  type: 'VISITS:ADD_VISIT';
  visit: Visit;
}

export const addVisit = (
  visit: Partial<Omit<Visit, 'id' | 'name'>> & Pick<Visit, 'name'>,
  id = uuid.v4()
): AddVisit => ({
  type: 'VISITS:ADD_VISIT',
  visit: {
    guestIds: [],
    hostIds: [],
    recipeIds: [],
    ...visit,
    id,
  },
});

interface RemoveVisit {
  type: 'VISITS:REMOVE_VISIT';
  id: string;
}

export const removeVisit = (id: string): RemoveVisit => ({
  type: 'VISITS:REMOVE_VISIT',
  id,
});

interface AddGuest {
  type: 'VISITS:ADD_GUEST';
  visitId: string;
  friendId: string;
}

export const addGuest = (visitId: string, friendId: string): AddGuest => ({
  type: 'VISITS:ADD_GUEST',
  visitId,
  friendId,
});

interface RemoveGuest {
  type: 'VISITS:REMOVE_GUEST';
  visitId: string;
  friendId: string;
}

export const removeGuest = (
  visitId: string,
  friendId: string
): RemoveGuest => ({
  type: 'VISITS:REMOVE_GUEST',
  visitId,
  friendId,
});

interface AddHost {
  type: 'VISITS:ADD_HOST';
  visitId: string;
  friendId: string;
}

export const addHost = (visitId: string, friendId: string): AddHost => ({
  type: 'VISITS:ADD_HOST',
  visitId,
  friendId,
});

interface RemoveHost {
  type: 'VISITS:REMOVE_HOST';
  visitId: string;
  friendId: string;
}

export const removeHost = (visitId: string, friendId: string): RemoveHost => ({
  type: 'VISITS:REMOVE_HOST',
  visitId,
  friendId,
});

interface EditName {
  type: 'VISITS:EDIT_NAME';
  name: string;
  visitId: string;
}

export const editName = (visitId: string, name: string): EditName => ({
  type: 'VISITS:EDIT_NAME',
  name,
  visitId,
});

interface EditNote {
  type: 'VISITS:EDIT_NOTE';
  note: string;
  visitId: string;
}

export const editNote = (visitId: string, note: string): EditNote => ({
  type: 'VISITS:EDIT_NOTE',
  note,
  visitId,
});

interface EditDate {
  type: 'VISITS:EDIT_DATE';
  date: string;
  visitId: string;
}

export const editDate = (visitId: string, date: string): EditDate => ({
  type: 'VISITS:EDIT_DATE',
  date,
  visitId,
});

interface AddRecipe {
  type: 'VISITS:ADD_RECIPE';
  visitId: string;
  recipeId: string;
}

export const addRecipe = (visitId: string, recipeId: string): AddRecipe => ({
  type: 'VISITS:ADD_RECIPE',
  visitId,
  recipeId,
});

interface RemoveRecipe {
  type: 'VISITS:REMOVE_RECIPE';
  visitId: string;
  recipeId: string;
}

export const removeRecipe = (
  visitId: string,
  recipeId: string
): RemoveRecipe => ({
  type: 'VISITS:REMOVE_RECIPE',
  visitId,
  recipeId,
});

export type VisitActions =
  | AddGuest
  | RemoveGuest
  | AddHost
  | RemoveHost
  | EditName
  | EditNote
  | EditDate
  | AddRecipe
  | RemoveRecipe;

export type VisitsActions = SetInitialAction | AddVisit | RemoveVisit;
