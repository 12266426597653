import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Space } from '../../../common/components/Space';
import { SpecialCost as SpecialCostType } from '../../../common/types/SpecialCost';
import { SpecialCost } from './SpecialCost';

interface SpecialCostListProps {
  editing: boolean;
  specialCosts: SpecialCostType[];
}

const StyledSpecialCostList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpecialCostList: React.FC<SpecialCostListProps> = ({
  editing,
  specialCosts,
}) => {
  return (
    <StyledSpecialCostList>
      {sortBy(specialCosts, 'name').map((specialCost, index) => (
        <React.Fragment key={specialCost.id}>
          {index !== 0 && <Space num={2} />}
          <SpecialCost editing={editing} specialCost={specialCost} />
        </React.Fragment>
      ))}
    </StyledSpecialCostList>
  );
};
