import { Friend } from '../../common/types/Friend';
import { StoreState } from '../../rootReducer';
import { friendReducer } from './friendReducer';
import { FriendsActions } from './friendsActions';

export interface FriendsReducerState {
  list: Friend[];
  selectedId?: string;
}

const INITIAL_STATE: FriendsReducerState = {
  list: [],
};

export const friendsReducer = (
  state: FriendsReducerState = INITIAL_STATE,
  action: FriendsActions
): FriendsReducerState => {
  switch (action.type) {
    case 'FRIENDS:SET_INITIAL':
      return {
        ...action.payload.initial,
        list: action.payload.initial.list.map(friend => ({
          ...friend,
          friendGroupIds: friend.friendGroupIds || [],
          specialCostIds: friend.specialCostIds || [],
        })),
      };
    case 'ADD_FRIEND':
      return {
        ...state,
        list: [...state.list, action.friend],
      };
    case 'REMOVE_FRIEND':
      return {
        ...state,
        list: state.list.filter(friend => friend.id !== action.id),
      };
    case 'SELECT_FRIEND':
      return {
        ...state,
        selectedId: action.id,
      };
    case 'EDIT_FRIEND_NAME':
    case 'EDIT_FRIEND_NOTE':
    case 'FRIEND:ADD_FRIEND_GROUP':
    case 'FRIEND:REMOVE_FRIEND_GROUP':
    case 'FRIEND:ADD_SPECIAL_COST':
    case 'FRIEND:REMOVE_SPECIAL_COST':
      return {
        ...state,
        list: state.list.map(friend => {
          if (friend && friend.id === action.friendId) {
            return friendReducer(friend, action);
          }
          return friend;
        }),
      };
    default:
      return state;
  }
};

export const getFriendsState = (state: StoreState): FriendsReducerState =>
  state.friends;

export const getFriendsList = (state: StoreState): Friend[] =>
  getFriendsState(state).list;
export const getFriend = (state: StoreState, id: string): Friend | undefined =>
  getFriendsState(state).list.find(friend => friend.id === id);

export const getFriends = (
  state: StoreState,
  ids: string[]
): Array<Friend | undefined> => {
  return ids.map(id => getFriend(state, id));
};
