import { Friend } from '../../common/types/Friend';
import { FriendsActions } from './friendsActions';

export type FriendReducerState = Friend;

export const friendReducer = (
  state: FriendReducerState,
  action: FriendsActions
): FriendReducerState => {
  switch (action.type) {
    case 'EDIT_FRIEND_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'EDIT_FRIEND_NOTE':
      return {
        ...state,
        note: action.note,
      };
    case 'FRIEND:ADD_FRIEND_GROUP':
      return {
        ...state,
        friendGroupIds: [...state.friendGroupIds, action.friendGroupId],
      };
    case 'FRIEND:REMOVE_FRIEND_GROUP':
      return {
        ...state,
        friendGroupIds: state.friendGroupIds.filter(
          friendGroupId => friendGroupId !== action.friendGroupId
        ),
      };
    case 'FRIEND:ADD_SPECIAL_COST':
      return {
        ...state,
        specialCostIds: [...state.specialCostIds, action.specialCostId],
      };
    case 'FRIEND:REMOVE_SPECIAL_COST':
      return {
        ...state,
        specialCostIds: state.specialCostIds.filter(
          specialCostId => specialCostId !== action.specialCostId
        ),
      };
    default:
      return state;
  }
};
