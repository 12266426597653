import styled from '@emotion/styled';
import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { AddFriend } from '../../features/friends/components/AddFriend';
import { GuestSpecialCost } from '../../features/visits/components/guests/GuestSpecialCost';
import { showFriendGroupsInVisits } from '../features';
import { Friend } from '../types/Friend';
import { Space } from './Space';

interface EditableGuestListProps {
  onAdd: (id: string) => void;
  onAddHost: (id: string) => void;
  items: GuestListItem[];
  onRemove: (id: string) => void;
  onRemoveHost: (id: string) => void;
}

const StyledEditingList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface GuestListItem {
  checked: boolean;
  isHost: boolean;
  guest: Friend;
  subItems: {
    checked: boolean;
    id: string;
    label: string;
    onClick: () => void;
  }[];
}

const StyledEditingListItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSpecialCosts = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHost = styled.div<{ isHost: boolean }>`
  cursor: pointer;
  color: ${({ isHost }) => (isHost ? '#000' : '#bbb')};
`;

export const EditableGuestList: React.FC<EditableGuestListProps> = ({
  items,
  onAdd,
  onRemove,
  onAddHost,
  onRemoveHost,
}) => {
  return (
    <StyledEditingContainer>
      <StyledEditingList>
        {items.map((item, index) => {
          return (
            <React.Fragment key={item.guest.id}>
              {index !== 0 && <Space />}
              <StyledEditingListItem>
                <Checkbox
                  checked={item.checked}
                  onChange={() => {
                    if (item.checked) {
                      onRemove(item.guest.id);
                    } else {
                      onAdd(item.guest.id);
                    }
                  }}
                  label={item.guest.name}
                />
                <Space />
                <StyledHost
                  isHost={item.isHost}
                  onClick={() => {
                    if (item.isHost) {
                      onRemoveHost(item.guest.id);
                    } else {
                      onAddHost(item.guest.id);
                    }
                  }}
                >
                  Host
                </StyledHost>

                {item.guest.specialCostIds.length > 0 && (
                  <StyledSpecialCosts>
                    <Space />(
                    {item.guest.specialCostIds.map((specialCostId, index) => (
                      <React.Fragment key={specialCostId}>
                        {index !== 0 && (
                          <>
                            ,<Space />
                          </>
                        )}
                        <GuestSpecialCost specialCostId={specialCostId} />
                      </React.Fragment>
                    ))}
                    )
                  </StyledSpecialCosts>
                )}
              </StyledEditingListItem>
              {item.checked && showFriendGroupsInVisits && (
                <>
                  <Space />
                  <div style={{ marginLeft: '24px' }}>
                    {item.subItems.map((subItem, subItemIndex) => {
                      return (
                        <React.Fragment key={subItem.id}>
                          {subItemIndex !== 0 && <Space />}
                          <Checkbox
                            checked={subItem.checked}
                            onChange={subItem.onClick}
                            label={subItem.label}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              )}
            </React.Fragment>
          );
        })}
        <Space />
        <AddFriend
          onFriendAdded={(id: string) => {
            onAdd(id);
          }}
        />
      </StyledEditingList>
    </StyledEditingContainer>
  );
};
