import { FriendGroup } from '../../common/types/FriendGroup';
import { StoreState } from '../../rootReducer';
import { friendGroupReducer } from './friendGroupReducer';
import { FriendGroupsActions } from './friendGroupsActions';

export interface FriendGroupsReducerState {
  list: FriendGroup[];
  selectedId?: string;
}

const INITIAL_STATE: FriendGroupsReducerState = {
  list: [],
};

export const friendGroupsReducer = (
  state: FriendGroupsReducerState = INITIAL_STATE,
  action: FriendGroupsActions
): FriendGroupsReducerState => {
  switch (action.type) {
    case 'FRIEND_GROUPS:SET_INITIAL':
      return action.payload.initial;
    case 'ADD_FRIEND_GROUP':
      return {
        ...state,
        list: [...state.list, action.friendGroup],
      };
    case 'REMOVE_FRIEND_GROUP':
      return {
        ...state,
        list: state.list.filter(friendGroup => friendGroup.id !== action.id),
      };
    case 'SELECT_FRIEND_GROUP':
      return {
        ...state,
        selectedId: action.id,
      };
    case 'EDIT_FRIEND_GROUP_NAME':
    case 'EDIT_FRIEND_GROUP_NOTE':
      return {
        ...state,
        list: state.list.map(friendGroup => {
          if (friendGroup && friendGroup.id === action.friendGroupId) {
            return friendGroupReducer(friendGroup, action);
          }
          return friendGroup;
        }),
      };
    default:
      return state;
  }
};

export const getFriendGroupsState = (
  state: StoreState
): FriendGroupsReducerState => state.friendGroups;

export const getFriendGroupList = (state: StoreState): FriendGroup[] =>
  getFriendGroupsState(state).list;
export const getFriendGroup = (
  state: StoreState,
  id: string
): FriendGroup | undefined =>
  getFriendGroupsState(state).list.find(friend => friend.id === id);

export const getFriendGroups = (
  state: StoreState,
  ids: string[]
): Array<FriendGroup | undefined> => {
  return ids.map(id => getFriendGroup(state, id));
};
