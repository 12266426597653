import styled from '@emotion/styled';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';
import { Space } from '../common/components/Space';
import { HeaderLink } from './HeaderLink';
import { pages } from './pages';

interface NavBarDesktopProps {
  authenticated: boolean;
  logout: () => Promise<void>;
}

const Row = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledLoginItem = styled.div`
  color: #4183c4;
`;

export const NavBarDesktop: React.FC<NavBarDesktopProps> = ({
  authenticated,
  logout,
}) => {
  return (
    <Menu fixed="top">
      <Container>
        <Menu.Item header>
          <Link to="/">
            <Row>
              <FontAwesomeIcon icon={faUtensils} size={'2x'} />
              <Space />
              The visit book
            </Row>
          </Link>
        </Menu.Item>
        {authenticated && (
          <>
            {pages.map(page => (
              <HeaderLink
                key={page.name}
                name={page.name}
                to={page.to}
                activePaths={page.activePaths}
              />
            ))}
            <Menu.Menu position="right">
              <Menu.Item onClick={logout}>
                <StyledLoginItem>Logout</StyledLoginItem>
              </Menu.Item>
            </Menu.Menu>
          </>
        )}
      </Container>
    </Menu>
  );
};
