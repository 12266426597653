import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '../../../common/components/Label';
import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { Ingredient } from '../../../common/types/Ingredient';
import { RecipeTypeEnum } from '../../../common/types/Recipe';
import { notEmpty } from '../../../common/utils/notEmpty';
import { StoreState } from '../../../rootReducer';
import { getSpecialCosts } from '../../specialCosts/specialCostsReducer';
import {
  addIngredient as addIngredientAction,
  addSpecialCost as addSpecialCostAction,
  editAbout as editAboutAction,
  editIngredient as editIngredientAction,
  editInstructions as editInstructionsAction,
  editLink as editLinkAction,
  editName as editNameAction,
  editPortions as editPortionsAction,
  editTime as editTimeAction,
  editType as editTypeAction,
  removeIngredient as removeIngredientAction,
  removeRecipe,
  removeSpecialCost as removeSpecialCostAction,
} from '../RecipesActions';
import { About } from './About';
import { Ingredients } from './Ingredients';
import { Instructions } from './Instructions';
import { Link } from './Link';
import { Name } from './Name';
import { Portions } from './Portions';
import { SpecialCosts } from './SpecialCosts';
import { Time } from './Time';
import { Type } from './Type';

interface EditableRecipeProps {
  about?: string;
  id: string;
  ingredients: Ingredient[];
  instructions?: string;
  link?: string;
  name: string;
  portions?: string;
  specialCostIds: string[];
  time?: string;
  type?: RecipeTypeEnum;
}

const StyledRemoveButtonContainer = styled.div`
  align-self: flex-end;
`;

export const EditableRecipe: React.FC<EditableRecipeProps> = ({
  about,
  id,
  ingredients,
  instructions,
  link,
  name,
  portions,
  specialCostIds,
  time,
  type,
}) => {
  const dispatch = useDispatch();

  const onDeleteRecipe = useCallback(() => {
    dispatch(removeRecipe(id));
  }, [dispatch, id]);

  const editName = useCallback(
    (text: string) => {
      dispatch(editNameAction(id, text));
    },
    [dispatch, id]
  );

  const editTime = useCallback(
    (time: string) => {
      dispatch(editTimeAction(id, time));
    },
    [dispatch, id]
  );

  const editType = useCallback(
    (type: RecipeTypeEnum) => {
      dispatch(editTypeAction(id, type));
    },
    [dispatch, id]
  );

  const editAbout = useCallback(
    (text: string) => {
      dispatch(editAboutAction(id, text));
    },
    [dispatch, id]
  );

  const editPortions = useCallback(
    (text: string) => {
      dispatch(editPortionsAction(id, text));
    },
    [dispatch, id]
  );

  const addIngredient = useCallback(
    (ingredient: Ingredient) => {
      dispatch(addIngredientAction(id, ingredient));
    },
    [dispatch, id]
  );

  const editIngredient = useCallback(
    (index: number, input: string) => {
      dispatch(editIngredientAction(id, index, { name: input }));
    },
    [dispatch, id]
  );

  const removeIngredient = useCallback(
    (index: number) => {
      dispatch(removeIngredientAction(id, index));
    },
    [dispatch, id]
  );

  const editInstructions = useCallback(
    (instructions: string) => {
      dispatch(editInstructionsAction(id, instructions));
    },
    [dispatch, id]
  );

  const editLink = useCallback(
    (link: string) => {
      dispatch(editLinkAction(id, link));
    },
    [dispatch, id]
  );

  const specialCosts = useSelector((state: StoreState) =>
    getSpecialCosts(state, specialCostIds)
  );

  const addSpecialCost = useCallback(
    (specialCostId: string) => {
      dispatch(addSpecialCostAction(id, specialCostId));
    },
    [dispatch, id]
  );

  const removeSpecialCost = useCallback(
    (specialCostId: string) => {
      dispatch(removeSpecialCostAction(id, specialCostId));
    },
    [dispatch, id]
  );

  return (
    <>
      <Label label={'Name'}>{<Name name={name} edit={editName} />}</Label>
      <Space num={2} />
      <Label label={'Time'}>{<Time time={time} edit={editTime} />}</Label>
      <Space num={2} />
      <Label label={'Type'}>{<Type type={type} edit={editType} />}</Label>
      <Space num={2} />
      <Label label={'Special cost'}>
        <SpecialCosts
          specialCosts={specialCosts.filter(notEmpty)}
          addSpecialCost={addSpecialCost}
          removeSpecialCost={removeSpecialCost}
        />
      </Label>
      <Space num={2} />
      <Label label={'Worth mentioning'}>
        <About about={about} edit={editAbout} />
      </Label>
      <Space num={2} />
      <Label label={'Link'}>
        <Link link={link} edit={editLink} />
      </Label>
      <Space num={2} />
      <Label label={'Portions'}>
        <Portions edit={editPortions} portions={portions} />
      </Label>
      <Space num={2} />
      <Label label={'Ingredients'}>
        <Ingredients
          addIngredient={addIngredient}
          editIngredient={editIngredient}
          ingredients={ingredients}
          removeIngredient={removeIngredient}
        />
      </Label>
      <Space num={2} />
      <Label label={'Instructions'}>
        <Instructions
          editInstructions={editInstructions}
          instructions={instructions}
        />
      </Label>
      <StyledRemoveButtonContainer>
        <RemoveButton label={'Delete recipe'} onClick={onDeleteRecipe} />
      </StyledRemoveButtonContainer>
    </>
  );
};
