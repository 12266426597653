import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import * as React from 'react';

import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { Ingredient as IngredientType } from '../../../common/types/Ingredient';
import { AddIngredient } from './AddIngredient';
import { Ingredient } from './Ingredient';

interface IngredientsProps {
  addIngredient: (ingredient: IngredientType) => void;
  editIngredient: (index: number, input: string) => void;
  removeIngredient: (index: number) => void;
  ingredients: IngredientType[];
}

const StyledIngredient = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledEditingList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Ingredients: React.FC<IngredientsProps> = ({
  addIngredient,
  editIngredient,
  ingredients,
  removeIngredient,
}) => {
  return (
    <div>
      <StyledEditingContainer>
        <StyledEditingList>
          {sortBy(ingredients, 'name').map((ingredient, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <Space />}
                <StyledIngredient>
                  <Ingredient
                    editIngredient={editIngredient}
                    index={index}
                    ingredient={ingredient}
                  />
                  <Space />
                  <RemoveButton
                    onClick={() => {
                      removeIngredient(index);
                    }}
                    label={'Remove'}
                  />
                </StyledIngredient>
              </React.Fragment>
            );
          })}
          <Space />
          <AddIngredient addIngredient={addIngredient} />
        </StyledEditingList>
      </StyledEditingContainer>
    </div>
  );
};
