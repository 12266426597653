import * as React from 'react';
import { FormEvent } from 'react';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';

interface TextAreaProps {
  label: string;
  onChange: (text: string) => void;
  value?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  onChange,
  value,
}) => {
  const onInputChange = (e: FormEvent<HTMLTextAreaElement>) => {
    // @ts-ignore
    onChange(e.target.value);
  };
  return (
    <SemanticTextArea
      onChange={onInputChange}
      placeholder={label}
      value={value}
    />
  );
};
