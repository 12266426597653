import { combineReducers } from 'redux';
import { authReducer, AuthReducerState } from './auth/authReducer';
import {
  friendGroupsReducer,
  FriendGroupsReducerState,
} from './features/friendGroups/friendGroupsReducer';
import {
  friendsReducer,
  FriendsReducerState,
} from './features/friends/friendsReducer';
import {
  recipesReducer,
  RecipesReducerState,
} from './features/recipes/RecipesReducer';
import {
  specialCostsReducer,
  SpecialCostsReducerState,
} from './features/specialCosts/specialCostsReducer';
import {
  visitsReducer,
  VisitsReducerState,
} from './features/visits/visitsReducer';

export interface StoreState {
  auth: AuthReducerState;
  friends: FriendsReducerState;
  friendGroups: FriendGroupsReducerState;
  recipes: RecipesReducerState;
  visits: VisitsReducerState;
  specialCosts: SpecialCostsReducerState;
}

export const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  friends: friendsReducer,
  friendGroups: friendGroupsReducer,
  recipes: recipesReducer,
  visits: visitsReducer,
  specialCosts: specialCostsReducer,
});
