import { compareDesc, format, parse, parseISO } from 'date-fns';
import { Visit } from '../../../common/types/Visit';
import { compareDates } from './compareDates';

export interface VisitOnDate {
  title: string;
  visits: Visit[];
}

export const splitVisitsOnDate = (visits: Visit[]): VisitOnDate[] => {
  const something = visits.reduce<Record<string, Visit[]>>(
    (previousValue, currentValue) => {
      try {
        if (currentValue.date) {
          const formattedDate = format(parseISO(currentValue.date), 'LLLL y');
          return {
            ...previousValue,
            [formattedDate]: [
              ...(previousValue[formattedDate]
                ? previousValue[formattedDate]
                : []),
              currentValue,
            ],
          };
        }
      } catch (e) {}
      return {
        ...previousValue,
        'No date': [
          ...(previousValue['No date'] ? previousValue['No date'] : []),
          currentValue,
        ],
      };
    },
    {}
  );
  return Object.entries(something)
    .map(([key, value]) => {
      return {
        title: key,
        visits: value.sort((a, b) => {
          return compareDates(a.date, b.date);
        }),
      };
    })
    .concat()
    .sort((a, b) => {
      if (a.title === 'No date') {
        return 1;
      }
      if (b.title === 'No date') {
        return -1;
      }

      const aDate = parse(a.title, 'LLLL y', new Date());
      const bDate = parse(b.title, 'LLLL y', new Date());
      return compareDesc(aDate, bDate);
    });
};
