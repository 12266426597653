import styled from '@emotion/styled';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Header } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { getFriendsList } from '../../../friends/friendsReducer';

interface FilterProps {
  filteredGuestsIds: string[];
  onFilteredGuestIds: Dispatch<SetStateAction<string[]>>;
}

const StyledFilter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Filter: React.FC<FilterProps> = ({
  filteredGuestsIds,
  onFilteredGuestIds,
}) => {
  const friends = useSelector(getFriendsList);

  const options: DropdownItemProps[] = friends.map(friend => ({
    key: friend.id,
    text: friend.name,
    value: friend.id,
  }));

  const onChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    // @ts-ignore
    onFilteredGuestIds(data.value);
  };

  return (
    <StyledFilter>
      <Header as="h4">Filter</Header>
      <Dropdown
        placeholder="Friends"
        fluid
        onChange={onChange}
        multiple
        selection
        options={options}
        value={filteredGuestsIds}
      />
    </StyledFilter>
  );
};
