import * as React from 'react';
import { TextInput } from '../../../common/components/TextInput';

interface PortionsProps {
  portions?: string;
  edit: (text: string) => void;
}

export const Portions: React.FC<PortionsProps> = ({ edit, portions }) => {
  return <TextInput value={portions} onChange={edit} label="Portions" />;
};
