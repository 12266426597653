import styled from '@emotion/styled';
import * as React from 'react';
import { Space } from '../../common/components/Space';
import { Recipe } from '../../common/types/Recipe';
import { CourseList } from './CourseList';

interface DinnerMenuProps {
  onClick?: () => void;
  recipes: Recipe[];
}

const StyledRecipesMenu = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: 40px auto 40px;
  border: 1px solid #ababab;
  background-color: #fbfbf8;
  box-shadow: 0 1px 3px 0 #bcbdbd, 0 0 0 1px #d4d4d5;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
`;

const MenuHorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cdcdcd;
`;

const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
`;

const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  background-color: #eeeeee;
`;

const StyledCorner = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Corner: React.FC = () => (
  <StyledCorner>
    <img
      src={'/covered-food-plate.svg'}
      alt="diamond"
      width={'16px'}
      height={'16px'}
    />
  </StyledCorner>
);

export const DinnerMenu: React.FC<DinnerMenuProps> = ({ onClick, recipes }) => {
  return (
    <StyledRecipesMenu onClick={onClick}>
      <Corner />
      <LineContainer>
        <HorizontalLine />
      </LineContainer>
      <Corner />
      <LineContainer>
        <VerticalLine />
      </LineContainer>
      <StyledContent>
        <StyledTitle>Menu</StyledTitle>
        <Space />
        <MenuHorizontalLine />
        <CourseList recipes={recipes} />
      </StyledContent>
      <LineContainer>
        <VerticalLine />
      </LineContainer>
      <Corner />
      <LineContainer>
        <HorizontalLine />
      </LineContainer>
      <Corner />
    </StyledRecipesMenu>
  );
};
