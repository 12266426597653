import * as React from 'react';
import { useCallback } from 'react';
import { TextInput } from '../../../common/components/TextInput';
import { Ingredient as IngredientType } from '../../../common/types/Ingredient';
import { transformIngredient } from '../../../common/utils/ingredientUtils';

interface IngredientProps {
  editIngredient: (index: number, input: string) => void;
  index: number;
  ingredient: IngredientType;
}

export const Ingredient: React.FC<IngredientProps> = ({
  editIngredient,
  index,
  ingredient,
}) => {
  const onEditIngredient = useCallback(
    (value: string) => {
      editIngredient(index, value);
    },
    [index, editIngredient]
  );

  return (
    <div>
      <TextInput
        onChange={onEditIngredient}
        value={transformIngredient(ingredient)}
        label="Ingredient name"
      />
    </div>
  );
};
