import { StoreState } from '../rootReducer';
import { AuthAction } from './authActions';

export interface AuthResult {
  email: string;
  uid: string;
}

export interface AuthReducerState {
  sessionInformationFetched: boolean;
  authResult?: AuthResult;
}

export const INITIAL_VALUE: AuthReducerState = {
  sessionInformationFetched: false,
  authResult: undefined,
};

export const authReducer = (
  state: AuthReducerState = INITIAL_VALUE,
  action: AuthAction
): AuthReducerState => {
  switch (action.type) {
    case 'AUTH:USER_LOGGED_IN':
      return {
        ...state,
        sessionInformationFetched: true,
        authResult: action.payload.authResult,
      };
    case 'AUTH:USER_LOGGED_OUT':
      return {
        ...state,
        sessionInformationFetched: true,
        authResult: undefined,
      };
    case 'AUTH:SET_SESSION_FETCHED':
      return {
        ...state,
        sessionInformationFetched: true,
        authResult: action.payload.authResult,
      };
    default:
      return state;
  }
};
const getAuth = (state: StoreState): AuthReducerState => state.auth;
export const getAuthResult = (state: StoreState): AuthResult | undefined =>
  getAuth(state).authResult;

export const getEmail = (state: StoreState): string =>
  getAuthResult(state) ? getAuthResult(state)!.email : '';

export const getUid = (state: StoreState): string =>
  getAuthResult(state) ? getAuthResult(state)!.uid : '';

export const isSessionInformationFetched = (state: StoreState): boolean => {
  return getAuth(state).sessionInformationFetched;
};

export const isAuthenticated = (state: StoreState): boolean => {
  return getAuthResult(state) ? !!getAuthResult(state)!.email : false;
};
