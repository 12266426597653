import * as React from 'react';
import { ChangeEvent } from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';

interface TextInputProps {
  disabled?: boolean;
  label: string;
  onChange: (text: string) => void;
  value?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  disabled,
  label,
  onChange,
  value,
}) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <div>
      <SemanticInput
        disabled={disabled}
        placeholder={disabled ? '' : label}
        onChange={onInputChange}
        value={value}
      />
    </div>
  );
};
