import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Space } from '../../../common/components/Space';
import { FriendGroup as FriendGroupType } from '../../../common/types/FriendGroup';
import { FriendGroup } from './FriendGroup';

interface FriendGroupListProps {
  editing: boolean;
  friendGroups: FriendGroupType[];
}

const StyledFriendGroupList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin: 8px 0;
  }
`;

export const FriendGroupList: React.FC<FriendGroupListProps> = ({
  editing,
  friendGroups,
}) => {
  return (
    <StyledFriendGroupList>
      {sortBy(friendGroups, 'name').map((friendGroup, index) => (
        <React.Fragment key={friendGroup.id}>
          {index !== 0 && <Space num={2} />}
          <FriendGroup editing={editing} friendGroup={friendGroup} />
        </React.Fragment>
      ))}
    </StyledFriendGroupList>
  );
};
