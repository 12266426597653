import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { TextArea } from '../../../common/components/TextArea';
import { TextInput } from '../../../common/components/TextInput';
import { Wrap } from '../../../common/components/Wrap';
import { Friend as FriendType } from '../../../common/types/Friend';
import { notEmpty } from '../../../common/utils/notEmpty';
import { StoreState } from '../../../rootReducer';
import { getFriendGroups } from '../../friendGroups/friendGroupsReducer';
import { getSpecialCosts } from '../../specialCosts/specialCostsReducer';
import {
  addFriendGroup as addFriendGroupAction,
  addSpecialCost as addSpecialCostAction,
  editName,
  editNote,
  removeFriend,
  removeFriendGroup as removeFriendGroupAction,
  removeSpecialCost as removeSpecialCostAction,
} from '../friendsActions';
import { FriendGroups } from './FriendGroups';
import { SpecialCosts } from './SpecialCosts';

interface FriendProps {
  friend: FriendType;
  editing: boolean;
}

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledName = styled.div`
  font-size: 20px;
`;

const StyledNote = styled.div`
  width: 180px;
  word-break: break-word;
  hyphens: auto;
`;

export const Friend: React.FC<FriendProps> = ({
  editing,
  friend: { friendGroupIds, id, name, note, specialCostIds },
}) => {
  const dispatch = useDispatch();

  const updateName = useCallback(
    input => {
      dispatch(editName(id, input));
    },
    [dispatch, id]
  );

  const updateNote = useCallback(
    input => {
      dispatch(editNote(id, input));
    },
    [dispatch, id]
  );

  const friendGroups = useSelector((state: StoreState) =>
    getFriendGroups(state, friendGroupIds)
  );

  const addFriendGroup = useCallback(
    (friendGroupId: string) => {
      dispatch(addFriendGroupAction(id, friendGroupId));
    },
    [dispatch, id]
  );

  const removeFriendGroup = useCallback(
    (friendGroupId: string) => {
      dispatch(removeFriendGroupAction(id, friendGroupId));
    },
    [dispatch, id]
  );

  const specialCosts = useSelector((state: StoreState) =>
    getSpecialCosts(state, specialCostIds)
  );

  const addSpecialCost = useCallback(
    (specialCostId: string) => {
      dispatch(addSpecialCostAction(id, specialCostId));
    },
    [dispatch, id]
  );

  const removeSpecialCost = useCallback(
    (specialCostId: string) => {
      dispatch(removeSpecialCostAction(id, specialCostId));
    },
    [dispatch, id]
  );

  const onRemoveFriend = useCallback(() => {
    dispatch(removeFriend(id));
  }, [dispatch, id]);

  return (
    <Wrap condition={editing} wrap={children => <Form>{children}</Form>}>
      <div>
        <StyledLabel>
          {editing ? (
            <TextInput
              disabled={!editing}
              label={'Name'}
              onChange={updateName}
              value={name || ''}
            />
          ) : (
            <StyledName>{name}</StyledName>
          )}
          {editing && (
            <>
              <Space />
              <RemoveButton label={'Delete friend'} onClick={onRemoveFriend} />
            </>
          )}
        </StyledLabel>
        <Space />
        <div>
          <FriendGroups
            editing={editing}
            friendGroups={friendGroups}
            addFriendGroup={addFriendGroup}
            removeFriendGroup={removeFriendGroup}
          />
        </div>
        <Space />
        <div>
          <SpecialCosts
            editing={editing}
            specialCosts={specialCosts.filter(notEmpty)}
            addSpecialCost={addSpecialCost}
            removeSpecialCost={removeSpecialCost}
          />
        </div>
        <Space />
        <div>
          {editing ? (
            <TextArea
              label={'Notes'}
              onChange={updateNote}
              value={note || ''}
            />
          ) : (
            <StyledNote>{note || ''}</StyledNote>
          )}
        </div>
      </div>
    </Wrap>
  );
};
