import styled from '@emotion/styled';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';
import { Wrap } from '../../../common/components/Wrap';
import { Visit as VisitType } from '../../../common/types/Visit';
import { EditableVisit } from './EditableVisit';
import { NotEditableVisit } from './NotEditableVisit';

const StyledVisit = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingSaveIcon = styled.div`
  align-self: flex-end;
`;

interface VisitProps {
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  visit: VisitType;
}

export const Visit: React.FC<VisitProps> = ({
  editing,
  setEditing,
  visit: { date, guestIds, hostIds, id, name, note, recipeIds },
}) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Wrap condition={editing} wrap={children => <Form>{children}</Form>}>
      <StyledVisit>
        <StyledEditingSaveIcon
          onClick={() => {
            history.replace(location.pathname);
            setEditing(editing => !editing);
          }}
        >
          {editing ? (
            <Icon
              color={'green'}
              name={'check'}
              circular={true}
              link={true}
              size={'small'}
            />
          ) : (
            <Icon name={'edit'} circular={true} link={true} />
          )}
        </StyledEditingSaveIcon>
        {editing ? (
          <EditableVisit
            date={date}
            guestIds={guestIds}
            hostIds={hostIds}
            name={name}
            note={note}
            recipeIds={recipeIds}
            visitId={id}
          />
        ) : (
          <NotEditableVisit
            date={date}
            guestIds={guestIds}
            hostIds={hostIds}
            name={name}
            note={note}
            recipeIds={recipeIds}
            visitId={id}
          />
        )}
      </StyledVisit>
    </Wrap>
  );
};
