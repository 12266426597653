import styled from '@emotion/styled';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { Space } from '../../../../common/components/Space';
import { Friend } from '../../../../common/types/Friend';
import { Recipe } from '../../../../common/types/Recipe';
import { getNumberOfTimesRecipeHaveBeenUsedInVisitsBySelectedGuests } from '../../utils/getNumberOfTimesRecipeHaveBeenUsedInVisitsBySelectedGuests';
import { SpecialCostGuest } from '../../utils/getSpecialCostGuests';
import { getVisitsList } from '../../visitsReducer';
import { RecipeSpecialCost } from './RecipeSpecialCost';

const StyledRecipe = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface ListItem {
  checked: boolean;
  recipe: Recipe;
  specialCostGuests: SpecialCostGuest[];
}

interface RecipeListItemProps {
  guests: Friend[];
  index: number;
  item: ListItem;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
  visitId: string;
}

export const EditingRecipeListItem: React.FC<RecipeListItemProps> = ({
  guests,
  index,
  item,
  onAdd,
  onRemove,
  visitId,
}) => {
  const onChange = () => {
    if (item.checked) {
      onRemove(item.recipe.id);
    } else {
      onAdd(item.recipe.id);
    }
  };

  const inappropriateSpecialCost = item.recipe.specialCostIds.some(
    id =>
      !!item.specialCostGuests.find(
        specialCostGuest => specialCostGuest.specialCost.id === id
      )
  );
  const visits = useSelector(getVisitsList);

  return (
    <React.Fragment>
      {index !== 0 && <Space />}
      <StyledRecipe>
        <Checkbox
          checked={item.checked}
          onChange={onChange}
          label={item.recipe.name}
        />
        <Space />
        <div>
          (Given to guests before:{' '}
          {getNumberOfTimesRecipeHaveBeenUsedInVisitsBySelectedGuests(
            item.recipe.id,
            guests.map(guest => guest.id),
            visits.filter(visit => visit.id !== visitId)
          )}
          )
        </div>
        {item.recipe.specialCostIds.length > 0 && (
          <>
            <Space />(
            {item.recipe.specialCostIds.map(specialCostId => {
              return (
                <RecipeSpecialCost
                  inappropriateSpecialCost={inappropriateSpecialCost}
                  key={specialCostId}
                  specialCostId={specialCostId}
                  specialCostGuests={item.specialCostGuests}
                />
              );
            })}
            )
          </>
        )}
      </StyledRecipe>
    </React.Fragment>
  );
};
