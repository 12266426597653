import { Omit } from 'react-router';
import uuid from 'uuid';
import { FriendGroup } from '../../common/types/FriendGroup';
import { FriendGroupsReducerState } from './friendGroupsReducer';

export interface SetInitialAction {
  payload: {
    initial: FriendGroupsReducerState;
  };
  type: 'FRIEND_GROUPS:SET_INITIAL';
}

export const setInitial = (
  initial: FriendGroupsReducerState
): SetInitialAction => ({
  payload: {
    initial,
  },
  type: 'FRIEND_GROUPS:SET_INITIAL',
});

interface AddFriendGroupAction {
  friendGroup: FriendGroup;
  type: 'ADD_FRIEND_GROUP';
}

export const addFriendGroup = (
  friendGroup: Omit<FriendGroup, 'id'>,
  id = uuid.v4()
): AddFriendGroupAction => ({
  type: 'ADD_FRIEND_GROUP',
  friendGroup: {
    ...friendGroup,
    id,
  },
});

interface RemoveFriendGroupAction {
  id: string;
  type: 'REMOVE_FRIEND_GROUP';
}

export const removeFriendGroup = (id: string): RemoveFriendGroupAction => ({
  type: 'REMOVE_FRIEND_GROUP',
  id,
});

interface SelectFriendGroupAction {
  id: string;
  type: 'SELECT_FRIEND_GROUP';
}

export const selectFriendGroup = (id: string): SelectFriendGroupAction => ({
  type: 'SELECT_FRIEND_GROUP',
  id,
});

export interface EditNameAction {
  friendGroupId: string;
  name: string;
  type: 'EDIT_FRIEND_GROUP_NAME';
}

export const editName = (
  friendGroupId: string,
  name: string
): EditNameAction => ({
  friendGroupId,
  name,
  type: 'EDIT_FRIEND_GROUP_NAME',
});

export interface EditNoteAction {
  friendGroupId: string;
  note: string;
  type: 'EDIT_FRIEND_GROUP_NOTE';
}

export const editNote = (
  friendGroupId: string,
  note: string
): EditNoteAction => ({
  friendGroupId,
  note,
  type: 'EDIT_FRIEND_GROUP_NOTE',
});

export type FriendGroupsActions =
  | AddFriendGroupAction
  | RemoveFriendGroupAction
  | SelectFriendGroupAction
  | EditNoteAction
  | EditNameAction
  | SetInitialAction;
