import styled from '@emotion/styled';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notEmpty } from '../../common/utils/notEmpty';
import { StoreState } from '../../rootReducer';
import { getRecipes } from '../recipes/RecipesReducer';
import { getVisit } from '../visits/visitsReducer';
import { DinnerMenu } from './DinnerMenu';

interface DinnerMenuPageProps {}

const StyledRecipePage = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
`;

export const DinnerMenuPage: React.FC<DinnerMenuPageProps> = () => {
  const { visitId } = useParams();

  const recipes = useSelector((state: StoreState) => {
    if (visitId) {
      const visit = getVisit(state, visitId);
      if (visit) {
        return getRecipes(state, visit.recipeIds).filter(notEmpty);
      }
    }
    return [];
  });

  return (
    <StyledRecipePage>
      {recipes.length > 0 ? (
        <DinnerMenu recipes={recipes} />
      ) : (
        <div>No menu found</div>
      )}
    </StyledRecipePage>
  );
};
