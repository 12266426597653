import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Label } from '../../../common/components/Label';
import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { notEmpty } from '../../../common/utils/notEmpty';
import { StoreState } from '../../../rootReducer';
import { getFriends } from '../../friends/friendsReducer';
import { getRecipes } from '../../recipes/RecipesReducer';
import {
  addGuest as addGuestAction,
  addHost as addHostAction,
  editDate as editDateAction,
  editName as editNameAction,
  editNote as editNoteAction,
  removeGuest as removeGuestAction,
  removeHost as removeHostAction,
  removeVisit,
} from '../visitsActions';
import { DatePicker } from './DatePicker';
import { Guests } from './Guests';
import { Name } from './Name';
import { Note } from './Note';
import { Recipes } from './recipes/Recipes';

interface EditableVisitProps {
  date?: string;
  guestIds: string[];
  hostIds: string[];
  name: string;
  note?: string;
  recipeIds: string[];
  visitId: string;
}

const StyledRemoveButtonContainer = styled.div`
  align-self: flex-end;
`;

export const EditableVisit: React.FC<EditableVisitProps> = ({
  date,
  guestIds,
  hostIds,
  name,
  note,
  recipeIds,
  visitId,
}) => {
  const dispatch = useDispatch();

  const guests = useSelector((state: StoreState) =>
    getFriends(state, guestIds).filter(notEmpty)
  );
  const hosts = useSelector((state: StoreState) =>
    getFriends(state, hostIds).filter(notEmpty)
  );

  const recipes = useSelector((state: StoreState) =>
    getRecipes(state, recipeIds).filter(notEmpty)
  );

  const onRemoveVisit = useCallback(() => {
    dispatch(removeVisit(visitId));
  }, [dispatch, visitId]);

  const editDate = useCallback(
    (input: string) => {
      dispatch(editDateAction(visitId, input));
    },
    [dispatch, visitId]
  );

  const editName = useCallback(
    input => {
      dispatch(editNameAction(visitId, input));
    },
    [dispatch, visitId]
  );

  const editNote = useCallback(
    input => {
      dispatch(editNoteAction(visitId, input));
    },
    [dispatch, visitId]
  );

  const addGuest = useCallback(
    (guestId: string) => {
      dispatch(addGuestAction(visitId, guestId));
    },
    [dispatch, visitId]
  );

  const removeGuest = useCallback(
    (guestId: string) => {
      batch(() => {
        dispatch(removeGuestAction(visitId, guestId));
        if (hostIds.includes(guestId)) {
          dispatch(removeHostAction(visitId, guestId));
        }
      });
    },
    [dispatch, hostIds, visitId]
  );

  const addHost = useCallback(
    (hostId: string) => {
      batch(() => {
        dispatch(addHostAction(visitId, hostId));
        if (!guestIds.includes(hostId)) {
          dispatch(addGuestAction(visitId, hostId));
        }
      });
    },
    [dispatch, guestIds, visitId]
  );

  const removeHost = useCallback(
    (guestId: string) => {
      dispatch(removeHostAction(visitId, guestId));
    },
    [dispatch, visitId]
  );

  return (
    <>
      <Label label={'Name'}>
        <Name name={name} edit={editName} />
      </Label>
      <Space num={2} />
      <Label label={'Worth mentioning'}>
        <Note note={note} edit={editNote} />
      </Label>
      <Space num={2} />
      <Label label={'When?'}>
        <DatePicker date={date} edit={editDate} />
      </Label>
      <Space num={2} />
      <Label label={'With?'}>
        <Guests
          hosts={hosts.filter(notEmpty)}
          guests={guests.filter(notEmpty)}
          addGuest={addGuest}
          addHost={addHost}
          removeGuest={removeGuest}
          removeHost={removeHost}
        />
      </Label>
      <Space num={2} />
      <Label label={'What?'}>
        <Recipes
          guests={guests.filter(notEmpty)}
          recipes={recipes.filter(notEmpty)}
          visitId={visitId}
        />
      </Label>
      <Space num={2} />
      <StyledRemoveButtonContainer>
        <RemoveButton label={'Remove visit'} onClick={onRemoveVisit} />
      </StyledRemoveButtonContainer>
    </>
  );
};
