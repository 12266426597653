import styled from '@emotion/styled';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Space } from '../../../common/components/Space';
import { notEmpty } from '../../../common/utils/notEmpty';
import { StoreState } from '../../../rootReducer';
import { getFriends } from '../../friends/friendsReducer';
import { DinnerMenu } from '../../menu/DinnerMenu';
import { getRecipes } from '../../recipes/RecipesReducer';

interface NotEditableVisitProps {
  date?: string;
  guestIds: string[];
  hostIds: string[];
  name: string;
  note?: string;
  recipeIds: string[];
  visitId: string;
}

const StyledNotEditableVisit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StyledDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledNote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledGuests = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledGuest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
`;

export const NotEditableVisit: React.FC<NotEditableVisitProps> = ({
  date,
  guestIds,
  hostIds,
  name,
  note,
  recipeIds,
}) => {
  const hosts = useSelector((state: StoreState) =>
    getFriends(state, hostIds).filter(notEmpty)
  );

  const guests = useSelector((state: StoreState) =>
    getFriends(state, guestIds).filter(notEmpty)
  );

  const recipes = useSelector((state: StoreState) =>
    getRecipes(state, recipeIds).filter(notEmpty)
  );

  return (
    <StyledNotEditableVisit>
      <StyledTitle>
        <StyledName>{name}</StyledName>
        {date && (
          <>
            <Space />
            <StyledDate>({date})</StyledDate>
          </>
        )}
      </StyledTitle>
      {note && (
        <>
          <Space num={2} />
          <StyledNote>{note}</StyledNote>
        </>
      )}
      <Space num={2} />
      <StyledGuests>
        {guests.map((guest, index) => (
          <React.Fragment key={guest.id}>
            {index !== 0 && <Space />}
            {!!hosts.find(host => host.id === guest.id) && (
              <>
                <FontAwesomeIcon icon={faHome} />
                <Space />
              </>
            )}
            <StyledGuest>{guest.name}</StyledGuest>
          </React.Fragment>
        ))}
      </StyledGuests>
      {recipes.length > 0 && (
        <>
          <Space num={2} />
          <DinnerMenu recipes={recipes} />
        </>
      )}
    </StyledNotEditableVisit>
  );
};
