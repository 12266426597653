export const pages: { activePaths: string[]; name: string; to: string }[] = [
  {
    activePaths: ['/'],
    name: 'Home',
    to: '/',
  },
  {
    activePaths: ['/recipes', '/recipe/:id'],
    name: 'Recipes',
    to: '/recipes',
  },
  {
    activePaths: ['/visits', '/visit/:id'],
    name: 'Visits',
    to: '/visits',
  },
  {
    activePaths: ['/friends'],
    name: 'Friends',
    to: '/friends',
  },
  {
    activePaths: ['/groups'],
    name: 'Groups',
    to: '/groups',
  },
];
