import styled from '@emotion/styled';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { Wrap } from '../../../common/components/Wrap';
import { Recipe as RecipeType } from '../../../common/types/Recipe';
import { EditableRecipe } from './EditableRecipe';
import { NotEditableRecipe } from './NotEditableRecipe';

const StyledRecipe = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingSaveIcon = styled.div`
  align-self: flex-end;
`;

interface RecipeProps {
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  recipe: RecipeType;
}

export const Recipe: React.FC<RecipeProps> = ({
  recipe: {
    about,
    id,
    ingredients,
    instructions,
    link,
    name,
    portions,
    specialCostIds,
    time,
    type,
  },
  setEditing,
  editing,
}) => {
  return (
    <Wrap condition={editing} wrap={children => <Form>{children}</Form>}>
      <StyledRecipe>
        <StyledEditingSaveIcon onClick={() => setEditing(editing => !editing)}>
          {editing ? (
            <Icon
              color={'green'}
              name={'check'}
              circular={true}
              link={true}
              size={'small'}
            />
          ) : (
            <Icon name={'edit'} circular={true} link={true} />
          )}
        </StyledEditingSaveIcon>
        {editing ? (
          <EditableRecipe
            about={about}
            id={id}
            ingredients={ingredients}
            instructions={instructions}
            link={link}
            name={name}
            portions={portions}
            specialCostIds={specialCostIds}
            time={time}
            type={type}
          />
        ) : (
          <NotEditableRecipe
            about={about}
            id={id}
            ingredients={ingredients}
            instructions={instructions}
            link={link}
            name={name}
            portions={portions}
            specialCostIds={specialCostIds}
            time={time}
            type={type}
          />
        )}
      </StyledRecipe>
    </Wrap>
  );
};
