import * as React from 'react';
import { RecipeList } from './RecipeList';

interface RecipesPageProps {}

export const RecipesPage: React.FC<RecipesPageProps> = () => {
  return (
    <div>
      <RecipeList />
    </div>
  );
};
