import * as firebase from 'firebase/app';

import 'firebase/database';
import { Omit } from './common/types/helpers/Omit';
import { StoreState } from './rootReducer';

const config = {
  apiKey: 'AIzaSyCxAcExrT98Jxh0RXW1Ts3ki3YKfKUaGjs',
  appId: '1:943034902799:web:1dc40cc9f61599c557f67a',
  authDomain: 'visitbook-d1b02.firebaseapp.com',
  databaseURL: 'https://visitbook-d1b02.firebaseio.com',
  projectId: 'visitbook-d1b02',
  storageBucket: 'visitbook-d1b02.appspot.com',
  messagingSenderId: '943034902799',
};

export type DatabaseState = Omit<StoreState, 'auth'>;

interface IDataBase {
  readData: () => Promise<DatabaseState | null>;
  storeData: (state: DatabaseState) => void;
}

export class Database implements IDataBase {
  database: firebase.database.Database;
  uid: string | undefined;

  constructor(uid: string) {
    this.uid = String(uid);

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.database = firebase.database();
    this.readData = this.readData.bind(this);
    this.storeData = this.storeData.bind(this);
  }

  readData() {
    if (this.database || !this.uid) {
      return this.database
        .ref(`state/${this.uid}`)
        .once('value')
        .then(snapshot => {
          const data = snapshot.val();
          return data ? JSON.parse(data.state) : null;
        });
    }
    return Promise.resolve(null);
  }

  storeData(state: DatabaseState) {
    if (this.uid) {
      return this.database.ref(`state/${this.uid}`).set({
        state: JSON.stringify(state),
      });
    }
    return new Promise(() => ({}));
  }
}
