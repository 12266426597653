import styled from '@emotion/styled';
import * as React from 'react';
import { Label as SemanticLabel } from 'semantic-ui-react';

interface LabelProps {
  label: string;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.div`
  margin-bottom: 8px;
`;

const StyledContent = styled.div`
  padding-left: 16px;
`;

export const Label: React.FC<LabelProps> = ({ children, label }) => {
  return (
    <Column>
      <StyledLabel>
        <SemanticLabel>{label}</SemanticLabel>
      </StyledLabel>
      <StyledContent>{children}</StyledContent>
    </Column>
  );
};
