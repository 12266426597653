import { AuthResult } from './authReducer';

export interface UserLoggedInAction {
  payload: {
    authResult: AuthResult;
  };
  type: 'AUTH:USER_LOGGED_IN';
}

export const userLoggedIn = (authResult: AuthResult): UserLoggedInAction => ({
  payload: {
    authResult,
  },
  type: 'AUTH:USER_LOGGED_IN',
});

export interface UserLoggedOutAction {
  payload: {};
  type: 'AUTH:USER_LOGGED_OUT';
}

export const userLoggedOut = (): UserLoggedOutAction => ({
  payload: {},
  type: 'AUTH:USER_LOGGED_OUT',
});

export interface SetSessionFetchedAction {
  payload: {
    authResult?: AuthResult;
  };
  type: 'AUTH:SET_SESSION_FETCHED';
}

export const setSessionFetched = (
  authResult?: AuthResult
): SetSessionFetchedAction => ({
  payload: {
    authResult,
  },
  type: 'AUTH:SET_SESSION_FETCHED',
});

export type AuthAction =
  | UserLoggedInAction
  | UserLoggedOutAction
  | SetSessionFetchedAction;
