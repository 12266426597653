import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';
import { Space } from '../../common/components/Space';
import { Visit } from '../../common/types/Visit';
import { notEmpty } from '../../common/utils/notEmpty';
import { getFriendsList } from '../friends/friendsReducer';

interface VisitCellProps {
  visit: Visit;
}

const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const VisitCell: React.FC<VisitCellProps> = ({ visit }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/visit/${visit.id}`);
  };

  const allFriends = useSelector(getFriendsList);

  const guestNames = sortBy(
    visit.guestIds
      .map(guestId => allFriends.find(guest => guest.id === guestId))
      .filter(notEmpty),
    'name'
  );

  return (
    <Card onClick={onClick} centered={true}>
      <Image src="https://react.semantic-ui.com/images/wireframe/image.png" />
      <Card.Content>
        <Card.Header>{visit.date || '___'}</Card.Header>
        <Card.Description>
          <StyledList>
            {guestNames.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index !== 0 && (
                    <>
                      <span>,</span>
                      <Space />
                    </>
                  )}
                  <div>{item.name}</div>
                </React.Fragment>
              );
            })}
          </StyledList>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
