import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { RemoveButton } from '../../../common/components/RemoveButton';
import { Space } from '../../../common/components/Space';
import { TextInput } from '../../../common/components/TextInput';
import { Wrap } from '../../../common/components/Wrap';
import { SpecialCost as SpecialCostType } from '../../../common/types/SpecialCost';
import { editName, removeSpecialCost } from '../specialCostsActions';

interface SpecialCostProps {
  specialCost: SpecialCostType;
  editing: boolean;
}

const StyledLabel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const SpecialCost: React.FC<SpecialCostProps> = ({
  editing,
  specialCost: { id, name },
}) => {
  const dispatch = useDispatch();

  const updateName = useCallback(
    input => {
      dispatch(editName(id, input));
    },
    [dispatch, id]
  );

  const onRemoveSpecialCost = useCallback(() => {
    dispatch(removeSpecialCost(id));
  }, [dispatch, id]);

  return (
    <Wrap condition={editing} wrap={children => <Form>{children}</Form>}>
      <div>
        {editing ? (
          <StyledLabel>
            <TextInput
              disabled={!editing}
              label={'Name'}
              onChange={updateName}
              value={name || ''}
            />
            <Space />
            <RemoveButton
              label={'Delete special cost'}
              onClick={onRemoveSpecialCost}
            />
          </StyledLabel>
        ) : (
          <div>{name}</div>
        )}
      </div>
    </Wrap>
  );
};
