import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import { MenuItemProps } from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import { Space } from '../../../common/components/Space';
import { splitRecipesOnCourseType } from '../../../common/utils/splitRecipesOnCourseType';
import { getRecipeList } from '../RecipesReducer';
import { AddRecipe } from './AddRecipe';
import { Recipe } from './Recipe';

export interface RecipeListProps {}

const StyledAddRecipe = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RecipeList: React.FC<RecipeListProps> = () => {
  const { id } = useParams();
  const history = useHistory();

  const recipes = useSelector(getRecipeList);
  const [activeId, setActiveId] = useState<string | undefined>(id);

  const itemClicked = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { active, id }: MenuItemProps
  ) => {
    if (!active) {
      history.push('/recipe/' + id);
    } else {
      history.push('/recipes');
    }
  };

  const onRecipeAdded = (id: string) => {
    setActiveId(id);
    setEditing(true);
  };

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setActiveId(id);
  }, [id]);

  const activeRecipe = recipes.find(recipe => recipe.id === activeId);

  return (
    <div>
      <Grid columns={2} stackable={true} reversed="computer">
        <Grid.Row stretched>
          <Grid.Column width={12}>
            <Segment>
              {activeRecipe ? (
                <Recipe
                  editing={editing}
                  setEditing={setEditing}
                  key={activeRecipe.id}
                  recipe={activeRecipe}
                />
              ) : (
                <div>No recipe selected. Please pick one.</div>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Menu fluid vertical>
              {splitRecipesOnCourseType(recipes).map(
                (recipeOnCourseType, index) => {
                  return (
                    <Menu.Item key={recipeOnCourseType.title}>
                      <Menu.Header>{recipeOnCourseType.title}</Menu.Header>
                      <Menu.Menu>
                        {recipeOnCourseType.recipes.map(recipe => (
                          <Menu.Item
                            key={recipe.id}
                            id={recipe.id}
                            active={activeId === recipe.id}
                            index={index}
                            onClick={itemClicked}
                          >
                            {recipe.name}
                          </Menu.Item>
                        ))}
                      </Menu.Menu>
                    </Menu.Item>
                  );
                }
              )}
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Space num={2} />
      <StyledAddRecipe>
        <AddRecipe onRecipeAdded={onRecipeAdded} />
      </StyledAddRecipe>
    </div>
  );
};
