import { compareDesc, isValid, parseISO } from 'date-fns';

export const compareDates = (
  dateA: string | undefined,
  dateB: string | undefined
) => {
  const aDate = dateA ? parseISO(dateA) : 0;
  const bDate = dateB ? parseISO(dateB) : 0;

  if (!isValid(aDate) && !isValid(bDate)) {
    return 0;
  } else if (!isValid(aDate)) {
    return 1;
  } else if (!isValid(bDate)) {
    return -1;
  }

  return compareDesc(aDate, bDate);
};
