import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { Friend } from '../../../../common/types/Friend';
import { Recipe as RecipeType } from '../../../../common/types/Recipe';
import { SpecialCost } from '../../../../common/types/SpecialCost';
import { notEmpty } from '../../../../common/utils/notEmpty';
import { splitRecipesOnCourseType } from '../../../../common/utils/splitRecipesOnCourseType';
import { StoreState } from '../../../../rootReducer';
import { editType } from '../../../recipes/RecipesActions';
import { getRecipeList } from '../../../recipes/RecipesReducer';
import { getSpecialCostList } from '../../../specialCosts/specialCostsReducer';
import { getSpecialCostGuests } from '../../utils/getSpecialCostGuests';
import { addRecipe, removeRecipe } from '../../visitsActions';
import { EditingRecipeList } from './EditingRecipeList';

interface RecipesProps {
  guests: Friend[];
  recipes: Array<RecipeType>;
  visitId: string;
}

export const Recipes: React.FC<RecipesProps> = ({
  guests,
  recipes,
  visitId,
}) => {
  const mapState = useCallback((state: StoreState) => getRecipeList(state), []);

  const availableRecipes = useSelector(mapState);

  const dispatch = useDispatch();

  const onAddRecipe = useCallback(
    (recipeId: string) => {
      dispatch(addRecipe(visitId, recipeId));
    },
    [dispatch, visitId]
  );

  const onRemoveRecipe = useCallback(
    (recipeId: string) => {
      dispatch(removeRecipe(visitId, recipeId));
    },
    [dispatch, visitId]
  );

  const allSpecialCosts = useSelector(getSpecialCostList);

  const specialCostGuests: {
    specialCost: SpecialCost;
    guests: Friend[];
  }[] = getSpecialCostGuests(guests.filter(notEmpty), allSpecialCosts);

  return (
    <>
      {splitRecipesOnCourseType(availableRecipes)
        .filter(recipeOnCourt => recipeOnCourt.recipes.length > 0)
        .map(recipeOnCourt => {
          const items = recipeOnCourt.recipes.map(courtRecipe => {
            const checked = !!recipes.find(recipe => {
              return recipe.id === courtRecipe.id;
            });
            return {
              specialCostGuests: specialCostGuests.filter(specialCostGuest =>
                courtRecipe.specialCostIds.includes(
                  specialCostGuest.specialCost.id
                )
              ),
              checked,
              recipe: courtRecipe,
            };
          });

          const onAdd = (id: string) => {
            if (recipeOnCourt.type) {
              dispatch(editType(id, recipeOnCourt.type));
            }
            onAddRecipe(id);
          };

          return (
            <React.Fragment key={recipeOnCourt.title}>
              <Header as={'h4'}>{recipeOnCourt.title}</Header>
              <EditingRecipeList
                guests={guests}
                items={items}
                onAdd={onAdd}
                onRemove={onRemoveRecipe}
                visitId={visitId}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};
