import styled from '@emotion/styled';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppWithLogin } from './AppWithLogin';
import { rootReducer } from './rootReducer';

const store = createStore(rootReducer, composeWithDevTools());

const StyledApp = styled('div')`
  min-height: 100vh;
  padding: 8px;
`;

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <StyledApp>
          <AppWithLogin store={store} />
        </StyledApp>
      </Router>
    </Provider>
  );
};
