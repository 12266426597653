import * as React from 'react';
import { Route } from 'react-router-dom';
import { FriendGroupsPage } from './features/friendGroups/components/FriendGroupPage';
import { FriendsPage } from './features/friends/components/FriendsPage';
import { Home } from './features/home/Home';
import { DinnerMenuPage } from './features/menu/DinnerMenuPage';
import { RecipesPage } from './features/recipes/components/RecipesPage';
import { SpecialCostPage } from './features/specialCosts/components/SpecialCostPage';
import { VisitsPage } from './features/visits/components/VisitsPage';

export const Main: React.FC = () => {
  return (
    <>
      <Route path="/" exact component={Home} />
      <Route path={['/recipes/', '/recipe/:id']} component={RecipesPage} />
      <Route path={['/visits/', '/visit/:id']} component={VisitsPage} />
      <Route path="/menu/:visitId" component={DinnerMenuPage} />
      <Route path="/friends/" component={FriendsPage} />
      <Route path="/groups/" component={FriendGroupsPage} />
      <Route path="/specialCosts/" component={SpecialCostPage} />
    </>
  );
};
