import styled from '@emotion/styled';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../rootReducer';
import { getSpecialCost } from '../../../specialCosts/specialCostsReducer';
import { SpecialCostGuest } from '../../utils/getSpecialCostGuests';

interface RecipeSpecialCostProps {
  inappropriateSpecialCost: boolean;
  specialCostId: string;
  specialCostGuests: SpecialCostGuest[];
}

const StyledSpecialCost = styled.div<{ inappropriateSpecialCost: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${({ inappropriateSpecialCost }) =>
    inappropriateSpecialCost ? 'indianred' : 'inherit'};
`;

export const RecipeSpecialCost: React.FC<RecipeSpecialCostProps> = ({
  inappropriateSpecialCost,
  specialCostGuests,
  specialCostId,
}) => {
  const specialCost = useSelector((state: StoreState) =>
    getSpecialCost(state, specialCostId)
  );

  if (!specialCost) {
    return null;
  }
  return (
    <StyledSpecialCost inappropriateSpecialCost={inappropriateSpecialCost}>
      {specialCost.name}
    </StyledSpecialCost>
  );
};
