import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { Space } from '../../../common/components/Space';
import { getFriendGroupList } from '../friendGroupsReducer';
import { AddFriendGroup } from './AddFriendGroup';
import { FriendGroupList } from './FriendGroupList';

interface FriendsPageProps {}

const StyledFriendsPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAddFriendGroup = styled.div`
  align-self: flex-end;
`;

export const FriendGroupsPage: React.FC<FriendsPageProps> = () => {
  const [editing, setEditing] = useState(false);

  const friendGroups = useSelector(getFriendGroupList);

  return (
    <StyledFriendsPage>
      {friendGroups.length > 0 && (
        <StyledAddFriendGroup onClick={() => setEditing(editing => !editing)}>
          {editing ? (
            <Icon color={'green'} name={'check'} circular={true} link={true} />
          ) : (
            <Icon name={'edit'} circular={true} link={true} />
          )}
        </StyledAddFriendGroup>
      )}
      <FriendGroupList editing={editing} friendGroups={friendGroups} />
      <Space num={2} />
      <StyledAddFriendGroup>
        <AddFriendGroup />
      </StyledAddFriendGroup>
    </StyledFriendsPage>
  );
};
