import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { RecipeTypeEnum } from '../../../common/types/Recipe';

interface TypeProps {
  type?: RecipeTypeEnum;
  edit: (text: RecipeTypeEnum) => void;
}

const typeOptions: DropdownItemProps[] = [
  { key: 'Appetizer', text: 'Appetizer', value: RecipeTypeEnum.Appetizer },
  { key: 'MainCourse', text: 'Main course', value: RecipeTypeEnum.MainCourse },
  { key: 'Dessert', text: 'Dessert', value: RecipeTypeEnum.Dessert },
];

export const Type: React.FC<TypeProps> = ({ type, edit }) => {
  const onChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    // @ts-ignore
    edit(data.value);
  };
  return (
    <Dropdown
      onChange={onChange}
      placeholder="Select Type"
      selection
      options={typeOptions}
      value={type}
    />
  );
};
