import { SpecialCost } from '../../common/types/SpecialCost';
import { StoreState } from '../../rootReducer';
import { SpecialCostsActions } from './specialCostsActions';

export interface SpecialCostsReducerState {
  list: SpecialCost[];
}

const INITIAL_STATE: SpecialCostsReducerState = {
  list: [],
};

export const specialCostsReducer = (
  state: SpecialCostsReducerState = INITIAL_STATE,
  action: SpecialCostsActions
): SpecialCostsReducerState => {
  switch (action.type) {
    case 'SPECIAL_COSTS:SET_INITIAL':
      return action.payload.initial;
    case 'SPECIAL_COSTS:ADD_SPECIAL_COST':
      return {
        ...state,
        list: [...state.list, action.specialCost],
      };
    case 'SPECIAL_COSTS:REMOVE_SPECIAL_COST':
      return {
        ...state,
        list: state.list.filter(specialCost => specialCost.id !== action.id),
      };
    default:
      return state;
  }
};

export const getSpecialCostsState = (
  state: StoreState
): SpecialCostsReducerState => state.specialCosts;

export const getSpecialCostList = (state: StoreState): SpecialCost[] =>
  getSpecialCostsState(state).list;
export const getSpecialCost = (
  state: StoreState,
  id: string
): SpecialCost | undefined =>
  getSpecialCostsState(state).list.find(specialCost => specialCost.id === id);

export const getSpecialCosts = (
  state: StoreState,
  ids: string[]
): Array<SpecialCost | undefined> => {
  return ids.map(id => getSpecialCost(state, id));
};
