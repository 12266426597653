import { Ingredient, IngredientInput } from '../types/Ingredient';

export const newIngredient: Ingredient = (
  amount: string,
  unit: string,
  name: string
) => ({
  amount,
  unit,
  name,
});

export const transformIngredient = (
  item: Ingredient = { name: '' }
): string => {
  let input = '';
  if (item.amount) {
    input += `${item.amount}`;
  }
  if (item.amount !== undefined && item.unit !== undefined) {
    input += ' ';
  }
  if (item.unit) {
    input += `${item.unit}`;
  }
  if (item.unit !== undefined && item.name !== undefined) {
    input += ' ';
  }
  if (item.name) {
    input += `${item.name}`;
  }
  return input;
};

const getAmount = (match: RegExpMatchArray): string | undefined => {
  if (match[1]) {
    return match[1].trim();
  }
  return undefined;
};

const getUnit = (match: RegExpMatchArray): string | undefined => {
  if (match[2]) {
    return match[2].trim();
  } else if (match[1] && match[1].substring(match[1].length - 1) === ' ') {
    return '';
  }
  return undefined;
};

const getName = (match: RegExpMatchArray): string | undefined => {
  if (match[3]) {
    return match[3].trim();
  } else if (match[2] && match[2].substring(match[2].length - 1) === ' ') {
    return '';
  }
  return undefined;
};

export const getMatchingItem = (text: string): IngredientInput => {
  const regExp = /([0-9]+\s?)([A-Za-z]+\s?)?(.*)?/;
  const match = text.match(regExp);
  if (match) {
    return {
      input: match[0],
      amount: getAmount(match),
      unit: getUnit(match),
      name: getName(match),
    };
  }
  return {
    name: text,
  };
};
