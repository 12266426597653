import * as React from 'react';
import { useSelector } from 'react-redux';
import { Friend } from '../../../common/types/Friend';
import { sortFriends } from '../../../common/utils/sortFriends';
import { getFriendsList } from '../../friends/friendsReducer';
import { EditableFriendList } from './EditableFriendList';

interface FriendsProps {
  addFriend: (guestId: string) => void;
  editing: boolean;
  friendsInGroup: Array<Friend | undefined>;
  removeFriend: (guestId: string) => void;
}

export const Friends: React.FC<FriendsProps> = ({
  addFriend,
  editing,
  friendsInGroup,
  removeFriend,
}) => {
  const allFriends = useSelector(getFriendsList);

  const items = sortFriends(allFriends).map((friend, index) => {
    const checked = !!friendsInGroup.find(friendInGroup => {
      return friendInGroup !== undefined && friendInGroup.id === friend.id;
    });

    return {
      checked,
      id: friend.id,
      label: friend.name,
    };
  });

  return (
    <EditableFriendList
      editing={editing}
      onAdd={addFriend}
      items={items}
      onRemove={removeFriend}
    />
  );
};
