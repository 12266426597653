import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { AddSpecialCost } from '../../features/specialCosts/components/AddSpecialCost';
import { Space } from './Space';

interface EditableSpecialCostListProps {
  editing: boolean;
  onAdd: (id: string) => void;
  items: ListItem[];
  onRemove: (id: string) => void;
}

const StyledList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledEditingList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ListItem {
  checked: boolean;
  id: string;
  label: string;
}

export const EditableSpecialCostList: React.FC<EditableSpecialCostListProps> = ({
  editing,
  items,
  onAdd,
  onRemove,
}) => {
  return (
    <div>
      {editing ? (
        <StyledEditingContainer>
          <StyledEditingList>
            {sortBy(items, 'label').map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index !== 0 && <Space />}
                  <Checkbox
                    checked={item.checked}
                    onChange={e => {
                      if (item.checked) {
                        onRemove(item.id);
                      } else {
                        onAdd(item.id);
                      }
                    }}
                    label={item.label}
                  />
                </React.Fragment>
              );
            })}
            <Space />
            <AddSpecialCost
              onSpecialCostAdded={(id: string) => {
                onAdd(id);
              }}
            />
          </StyledEditingList>
        </StyledEditingContainer>
      ) : (
        <StyledList>
          {sortBy(items, 'label')
            .filter(item => item.checked)
            .map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index !== 0 && (
                    <>
                      <span>,</span>
                      <Space />
                    </>
                  )}
                  <div>{item.label}</div>
                </React.Fragment>
              );
            })}
        </StyledList>
      )}
    </div>
  );
};
