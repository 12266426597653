import styled from '@emotion/styled';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import { Space } from '../common/components/Space';
import { HeaderDropdownLink } from './HeaderDropdownLink';
import { pages } from './pages';

interface NavBarMobileProps {
  authenticated: boolean;
  logout: () => Promise<void>;
}

const Row = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledLoginItem = styled.div`
  color: #4183c4;
`;

const StyledDropdown = styled(Dropdown)`
  color: #4183c4;
`;

export const NavBarMobile: React.FC<NavBarMobileProps> = ({
  authenticated,
  logout,
}) => {
  return (
    <Menu fixed="top">
      <Container>
        <Menu.Item header>
          <Link to="/">
            <Row>
              <FontAwesomeIcon icon={faUtensils} size={'2x'} />
              <Space />
              The visit book
            </Row>
          </Link>
        </Menu.Item>
        {authenticated && (
          <Menu.Item position={'right'}>
            <StyledDropdown icon="bars" direction={'left'}>
              <Dropdown.Menu>
                {pages.map(page => (
                  <HeaderDropdownLink
                    key={page.name}
                    name={page.name}
                    to={page.to}
                  />
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}>
                  <StyledLoginItem>Logout</StyledLoginItem>
                </Dropdown.Item>
              </Dropdown.Menu>
            </StyledDropdown>
          </Menu.Item>
        )}
      </Container>
    </Menu>
  );
};
