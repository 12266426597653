import styled from '@emotion/styled';
import { faClock, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Space } from '../../../common/components/Space';
import { Ingredient } from '../../../common/types/Ingredient';
import { RecipeTypeEnum } from '../../../common/types/Recipe';
import { transformIngredient } from '../../../common/utils/ingredientUtils';
import { notEmpty } from '../../../common/utils/notEmpty';
import { StoreState } from '../../../rootReducer';
import { getSpecialCosts } from '../../specialCosts/specialCostsReducer';

interface NotEditableRecipeProps {
  about?: string;
  id: string;
  ingredients: Ingredient[];
  instructions?: string;
  link?: string;
  name: string;
  portions?: string;
  specialCostIds: string[];
  time?: string;
  type?: RecipeTypeEnum;
}

const StyledNotEditableRecipe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StyledInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledInformationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAbout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIngredientsAndInstructions = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSpecialCost = styled.div`
  border-radius: 16px;
  background-color: #efefef;
  padding: 4px 8px;
`;

const StyledInstructions = styled.div`
  white-space: pre-wrap;

  @media (min-width: 768px) {
    padding-left: 16px;
  }

  @media (max-width: 768px) {
    padding-top: 16px;
  }
`;

const StyledIngredients = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  white-space: nowrap;

  @media (min-width: 768px) {
    border-right: 1px solid #eee;
    padding-right: 16px;
  }

  @media (max-width: 768px) {
    border-top: 1px solid #eee;
    padding-top: 16px;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
  }
`;

export const NotEditableRecipe: React.FC<NotEditableRecipeProps> = ({
  about,
  id,
  ingredients,
  instructions,
  link,
  name,
  portions,
  specialCostIds,
  time,
  type,
}) => {
  const specialCosts = useSelector((state: StoreState) => {
    return getSpecialCosts(state, specialCostIds).filter(notEmpty);
  });

  return (
    <StyledNotEditableRecipe>
      <StyledTitle>{name}</StyledTitle>
      <Space num={2} />
      <StyledInformation>
        {time && (
          <StyledInformationItem title={'time'}>
            <FontAwesomeIcon icon={faClock} />
            <Space />
            <div>{time}</div>
            <Space num={2} />
          </StyledInformationItem>
        )}
        {portions && (
          <StyledInformationItem title={'portions'}>
            <FontAwesomeIcon icon={faUsers} />
            <Space />
            <div>{portions}</div>
            <Space num={2} />
          </StyledInformationItem>
        )}
        {specialCosts.length > 0 && (
          <>
            {sortBy(specialCosts, 'name').map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index !== 0 && (
                    <>
                      <Space />
                    </>
                  )}
                  <StyledSpecialCost>{item.name}</StyledSpecialCost>
                </React.Fragment>
              );
            })}
            <Space num={2} />
          </>
        )}
        {type && (
          <StyledInformationItem title={type}>
            <FontAwesomeIcon
              icon={faUtensils}
              size={type === RecipeTypeEnum.Appetizer ? '2x' : '1x'}
              color={'#999'}
            />
            <Space num={0.5} />
            <FontAwesomeIcon
              icon={faUtensils}
              size={type === RecipeTypeEnum.MainCourse ? '2x' : '1x'}
              color={'#999'}
            />
            <Space num={0.5} />
            <FontAwesomeIcon
              icon={faUtensils}
              size={type === RecipeTypeEnum.Dessert ? '2x' : '1x'}
              color={'#999'}
            />
            <Space />
          </StyledInformationItem>
        )}
      </StyledInformation>
      <Space num={2} />
      <StyledAbout>{about}</StyledAbout>
      <Space num={2} />
      {link && (
        <>
          <StyledLink>
            <a href={link} target={'_blank'} rel="noopener noreferrer">
              {link}
            </a>
          </StyledLink>
          <Space num={2} />
        </>
      )}
      <StyledIngredientsAndInstructions>
        <StyledIngredients>
          {sortBy(ingredients, 'name').map((ingredient, index) => {
            return (
              <React.Fragment key={ingredient.name}>
                {index !== 0 && <Space />}
                <div key={ingredient.name}>
                  {transformIngredient(ingredient)}
                </div>
              </React.Fragment>
            );
          })}
        </StyledIngredients>
        <StyledInstructions>{instructions}</StyledInstructions>
      </StyledIngredientsAndInstructions>
    </StyledNotEditableRecipe>
  );
};
