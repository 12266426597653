import * as React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { addVisit } from '../visitsActions';

interface AddVisitProps {
  onVisitAdded: (id: string) => void;
}

export const AddVisit: React.FC<AddVisitProps> = ({ onVisitAdded }) => {
  const dispatch = useDispatch();
  const onAddVisit = (name: string) => {
    const id = uuid.v4();
    dispatch(addVisit({ name }, id));
    onVisitAdded(id);
  };

  return (
    <AddEntity
      label={'New visit'}
      onAdd={onAddVisit}
      placeholder={'Visit name'}
    />
  );
};
