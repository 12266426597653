import * as React from 'react';
import { TextArea } from '../../../common/components/TextArea';

interface InstructionsProps {
  editInstructions: (instruction: string) => void;
  instructions?: string;
}

export const Instructions: React.FC<InstructionsProps> = ({
  editInstructions,
  instructions,
}) => {
  return (
    <TextArea
      value={instructions}
      onChange={editInstructions}
      label="Instructions"
    />
  );
};
