import { Ingredient } from './Ingredient';

export enum RecipeTypeEnum {
  Appetizer = 'Appetizer',
  MainCourse = 'Main course',
  Dessert = 'Dessert',
}

export interface Recipe {
  about?: string;
  id: string;
  ingredients: Ingredient[];
  instructions?: string;
  link?: string;
  name: string;
  portions?: string;
  specialCostIds: string[];
  time?: string;
  type?: RecipeTypeEnum;
}
