import * as React from 'react';
import { useSelector } from 'react-redux';
import { EditableSpecialCostList } from '../../../common/components/EditableSpecialCostList';
import { SpecialCost } from '../../../common/types/SpecialCost';
import { getSpecialCostList } from '../../specialCosts/specialCostsReducer';

interface SpecialCostsProps {
  addSpecialCost: (guestId: string) => void;
  specialCosts: SpecialCost[];
  removeSpecialCost: (guestId: string) => void;
}

export const SpecialCosts: React.FC<SpecialCostsProps> = ({
  addSpecialCost,
  specialCosts,
  removeSpecialCost,
}) => {
  const allSpecialCosts = useSelector(getSpecialCostList);

  // TODO: Make better selector
  const items = allSpecialCosts.map(allSpecialCost => {
    const checked = !!specialCosts.find(specialCost => {
      return specialCost.id === allSpecialCost.id;
    });
    return {
      checked,
      id: allSpecialCost.id,
      label: allSpecialCost.name,
    };
  });

  return (
    <EditableSpecialCostList
      editing={true}
      onAdd={addSpecialCost}
      items={items}
      onRemove={removeSpecialCost}
    />
  );
};
