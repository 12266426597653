import { Visit } from '../../common/types/Visit';
import { StoreState } from '../../rootReducer';
import { visitReducer } from './visitReducer';
import { VisitActions, VisitsActions } from './visitsActions';

export interface VisitsReducerState {
  list: Visit[];
}

const INITIAL_STATE: VisitsReducerState = {
  list: [],
};

export const visitsReducer = (
  state: VisitsReducerState = INITIAL_STATE,
  action: VisitsActions | VisitActions
): VisitsReducerState => {
  switch (action.type) {
    case 'VISITS:SET_INITIAL':
      return {
        ...action.payload.initial,
        list: action.payload.initial.list.map(visit => ({
          ...visit,
          guestIdsIds: visit.guestIds || [],
          hostIds: visit.hostIds || [],
          recipeIds: visit.recipeIds || [],
        })),
      };
    case 'VISITS:ADD_VISIT':
      return {
        ...state,
        list: [...state.list, action.visit],
      };
    case 'VISITS:REMOVE_VISIT':
      return {
        ...state,
        list: state.list.filter(visit => visit.id !== action.id),
      };
    case 'VISITS:ADD_GUEST':
    case 'VISITS:ADD_HOST':
    case 'VISITS:ADD_RECIPE':
    case 'VISITS:EDIT_NAME':
    case 'VISITS:EDIT_NOTE':
    case 'VISITS:EDIT_DATE':
    case 'VISITS:REMOVE_GUEST':
    case 'VISITS:REMOVE_HOST':
    case 'VISITS:REMOVE_RECIPE':
      return {
        ...state,
        list: state.list.map(visit => {
          if (visit.id === action.visitId) {
            return visitReducer(visit, action);
          }
          return visit;
        }),
      };
    default:
      return state;
  }
};

export const getVisitsState = (state: StoreState): VisitsReducerState =>
  state.visits;

export const getVisitsList = (state: StoreState): Visit[] =>
  getVisitsState(state).list;

export const getVisit = (
  state: StoreState,
  visitId: string
): Visit | undefined =>
  getVisitsList(state).find(visit => visit.id === visitId);

export const getGuestList = (state: StoreState, visitId: string): string[] => {
  const visit = getVisit(state, visitId);
  return visit ? visit.guestIds : [];
};
export const getNote = (
  state: StoreState,
  visitId: string
): string | undefined => {
  const visit = getVisit(state, visitId);
  return visit ? visit.note : undefined;
};
export const getRecipeList = (state: StoreState, visitId: string): string[] => {
  const visit = getVisit(state, visitId);
  return visit ? visit.recipeIds : [];
};
