import { Visit } from '../../../common/types/Visit';

export const getNumberOfTimesRecipeHaveBeenUsedInVisitsBySelectedGuests = (
  recipeId: string,
  selectedGuestsIds: string[],
  visits: Visit[]
) => {
  return visits
    .filter(visit => visit.recipeIds.includes(recipeId))
    .filter(visit =>
      visit.guestIds.some(guestId => selectedGuestsIds.includes(guestId))
    ).length;
};
