import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { getSpecialCostList } from '../specialCostsReducer';
import { AddSpecialCost } from './AddSpecialCost';
import { SpecialCostList } from './SpecialCostList';

interface SpecialCostPageProps {}

const StyledSpecialCostPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAddSpecialCost = styled.div`
  align-self: flex-end;
`;

export const SpecialCostPage: React.FC<SpecialCostPageProps> = () => {
  const [editing, setEditing] = useState(false);

  const specialCosts = useSelector(getSpecialCostList);

  return (
    <StyledSpecialCostPage>
      {specialCosts.length > 0 && (
        <StyledAddSpecialCost onClick={() => setEditing(editing => !editing)}>
          {editing ? (
            <Icon color={'green'} name={'check'} circular={true} link={true} />
          ) : (
            <Icon name={'edit'} circular={true} link={true} />
          )}
        </StyledAddSpecialCost>
      )}
      <SpecialCostList editing={editing} specialCosts={specialCosts} />
      <StyledAddSpecialCost>
        <AddSpecialCost />
      </StyledAddSpecialCost>
    </StyledSpecialCostPage>
  );
};
