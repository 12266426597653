import * as React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { addRecipe } from '../RecipesActions';

export interface AddRecipeProps {
  onRecipeAdded: (id: string) => void;
}

export const AddRecipe: React.FC<AddRecipeProps> = ({ onRecipeAdded }) => {
  const dispatch = useDispatch();
  const onAddRecipe = (name: string) => {
    const id = uuid.v4();
    dispatch(addRecipe({ name }, id));
    onRecipeAdded(id);
  };

  return (
    <AddEntity
      label={'New Recipe'}
      placeholder={'Recipe name'}
      onAdd={onAddRecipe}
    />
  );
};
