import * as React from 'react';
import { TextInput } from '../../../common/components/TextInput';

interface LinkProps {
  edit: (text: string) => void;
  link?: string;
}

export const Link: React.FC<LinkProps> = ({ edit, link }) => {
  return <TextInput value={link} onChange={edit} label="Link" />;
};
