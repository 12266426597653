import * as React from 'react';
import { TextInput } from '../../../common/components/TextInput';

interface NameProps {
  edit: (input: string) => void;
  name?: string;
}

export const Name: React.FC<NameProps> = ({ edit, name }) => {
  return <TextInput value={name} onChange={edit} label="Name" />;
};
