import * as React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../rootReducer';
import { getSpecialCost } from '../../../specialCosts/specialCostsReducer';

export interface GuestSpecialCostProps {
  specialCostId: string;
}

export const GuestSpecialCost: React.FC<GuestSpecialCostProps> = ({
  specialCostId,
}) => {
  const specialCost = useSelector((state: StoreState) =>
    getSpecialCost(state, specialCostId)
  );

  if (!specialCost) {
    return null;
  }
  return <div>{specialCost.name}</div>;
};
