import * as React from 'react';
import { VisitsList } from './VisitsList';

interface VisitsPageProps {}

export const VisitsPage: React.FC<VisitsPageProps> = () => {
  return (
    <div>
      <VisitsList />
    </div>
  );
};
