import { Visit } from '../../common/types/Visit';
import { VisitActions } from './visitsActions';

export const visitReducer = (state: Visit, action: VisitActions): Visit => {
  switch (action.type) {
    case 'VISITS:ADD_GUEST':
      return {
        ...state,
        guestIds: [...state.guestIds, action.friendId],
      };
    case 'VISITS:REMOVE_GUEST':
      return {
        ...state,
        guestIds: state.guestIds.filter(guestId => guestId !== action.friendId),
      };
    case 'VISITS:ADD_HOST':
      return {
        ...state,
        hostIds: [...state.hostIds, action.friendId],
      };
    case 'VISITS:REMOVE_HOST':
      return {
        ...state,
        hostIds: state.hostIds.filter(hostId => hostId !== action.friendId),
      };
    case 'VISITS:EDIT_DATE':
      return {
        ...state,
        date: action.date,
      };
    case 'VISITS:EDIT_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'VISITS:EDIT_NOTE':
      return {
        ...state,
        note: action.note,
      };
    case 'VISITS:ADD_RECIPE':
      return {
        ...state,
        recipeIds: [...state.recipeIds, action.recipeId],
      };
    case 'VISITS:REMOVE_RECIPE':
      return {
        ...state,
        recipeIds: state.recipeIds.filter(
          recipeId => recipeId !== action.recipeId
        ),
      };
    default:
      return state;
  }
};
