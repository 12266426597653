import styled from '@emotion/styled';
import * as React from 'react';
import { Space } from '../../../common/components/Space';
import { Friend as FriendType } from '../../../common/types/Friend';
import { sortFriends } from '../../../common/utils/sortFriends';
import { Friend } from './Friend';

interface FriendListProps {
  editing: boolean;
  friends: FriendType[];
}

const StyledFriendList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin: 8px 0;
  }
`;

export const FriendList: React.FC<FriendListProps> = ({ editing, friends }) => {
  return (
    <StyledFriendList>
      {sortFriends(friends).map((friend, index) => (
        <React.Fragment key={friend.id}>
          {index !== 0 && <Space num={2} />}
          <Friend editing={editing} friend={friend} />
        </React.Fragment>
      ))}
    </StyledFriendList>
  );
};
