import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

interface HeaderDropdownLinkProps {
  name: string;
  to: string;
}

export const HeaderDropdownLink: React.FC<HeaderDropdownLinkProps> = ({
  name,
  to,
}) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  return (
    <Dropdown.Item active={!!match}>
      <Link to={to}>{name}</Link>
    </Dropdown.Item>
  );
};
