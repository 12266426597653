import styled from '@emotion/styled';
import * as React from 'react';
import { Space } from '../../common/components/Space';
import { Recipe } from '../../common/types/Recipe';
import { splitRecipesOnCourseType } from '../../common/utils/splitRecipesOnCourseType';

interface CourseListProps {
  recipes: Recipe[];
}

const StyledList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const StyledRecipe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const StyledCourseType = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const StyledName = styled.div`
  text-align: center;
  font-size: 0.9rem;
`;

export const CourseList: React.FC<CourseListProps> = ({ recipes }) => {
  return (
    <StyledList>
      {splitRecipesOnCourseType(recipes).map(recipeOnCourseType =>
        recipeOnCourseType.recipes.map(recipe => {
          return (
            <StyledRecipe key={recipe.id}>
              <StyledCourseType>{recipeOnCourseType.title}</StyledCourseType>
              <Space />
              <StyledName>{recipe.name}</StyledName>
            </StyledRecipe>
          );
        })
      )}
    </StyledList>
  );
};
