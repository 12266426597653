import * as React from 'react';
import { useState } from 'react';
import { Confirm, Icon } from 'semantic-ui-react';

interface RemoveButtonProps {
  disabled?: boolean;
  onClick: () => void;
  label: string;
}

export const RemoveButton: React.FC<RemoveButtonProps> = ({
  disabled,
  onClick,
  label,
}) => {
  const [open, setOpen] = useState(false);

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    onClick();
    setOpen(false);
  };

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <Icon
          circular={true}
          disabled={disabled}
          link={!disabled}
          name={'minus'}
        />
      </div>
      <Confirm
        open={open}
        cancelButton="Cancel"
        confirmButton="Yes, remove!"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  );
};
