import * as React from 'react';
import { batch, useSelector } from 'react-redux';
import {
  EditableGuestList,
  GuestListItem,
} from '../../../common/components/EditableGuestList';
import { Friend } from '../../../common/types/Friend';
import { FriendGroup } from '../../../common/types/FriendGroup';
import { sortFriends } from '../../../common/utils/sortFriends';
import { getFriendGroupList } from '../../friendGroups/friendGroupsReducer';
import { getFriendsList } from '../../friends/friendsReducer';

interface GuestsProps {
  addGuest: (guestId: string) => void;
  addHost: (guestId: string) => void;
  guests: Friend[];
  hosts: Friend[];
  removeGuest: (guestId: string) => void;
  removeHost: (guestId: string) => void;
}

const allFriendsChecked = (
  allFriends: Array<Friend>,
  guests: Array<Friend>,
  friendGroup: FriendGroup
) =>
  allFriends
    .filter(friend => friend.friendGroupIds.includes(friendGroup.id))
    .every(friend => guests.some(guest => guest.id === friend.id));

export const Guests: React.FC<GuestsProps> = ({
  addGuest,
  addHost,
  guests,
  hosts,
  removeGuest,
  removeHost,
}) => {
  const allFriends = useSelector(getFriendsList);

  const friendGroups = useSelector(getFriendGroupList);

  const items: GuestListItem[] = sortFriends(allFriends).map(friend => {
    return {
      subItems: friend.friendGroupIds.map(groupId => {
        const friendGroup = friendGroups.find(group => group.id === groupId);
        const subItemChecked = friendGroup
          ? allFriendsChecked(allFriends, guests, friendGroup)
          : false;
        return {
          id: groupId,
          checked: subItemChecked,
          label: friendGroup ? friendGroup.name : '',
          onClick: () => {
            if (friendGroup) {
              const friendsInGroup = allFriends.filter(friend =>
                friend.friendGroupIds.includes(friendGroup.id)
              );
              batch(() => {
                friendsInGroup.forEach(friend => {
                  if (subItemChecked) {
                    removeGuest(friend.id);
                  } else {
                    addGuest(friend.id);
                  }
                });
              });
            }
          },
        };
      }),
      isHost: hosts.some(host => host.id === friend.id),
      checked: guests.some(guest => guest.id === friend.id),
      guest: friend,
    };
  });

  return (
    <EditableGuestList
      onAdd={addGuest}
      onAddHost={addHost}
      items={items}
      onRemove={removeGuest}
      onRemoveHost={removeHost}
    />
  );
};
