import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

interface HeaderLinkProps {
  activePaths: string[];
  name: string;
  to: string;
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  activePaths,
  name,
  to,
}) => {
  const match = useRouteMatch({
    path: activePaths,
    exact: true,
  });

  return (
    <Menu.Item active={!!match}>
      <Link to={to}>{name}</Link>
    </Menu.Item>
  );
};
