import { Friend } from '../../../common/types/Friend';
import { SpecialCost } from '../../../common/types/SpecialCost';

export interface SpecialCostGuest {
  specialCost: SpecialCost;
  guests: Friend[];
}

export const getSpecialCostGuests = (
  guests: Array<Friend>,
  allSpecialCosts: SpecialCost[]
): SpecialCostGuest[] =>
  allSpecialCosts
    .map(specialCost => ({
      specialCost,
      guests: guests.filter(guest =>
        guest.specialCostIds.includes(specialCost.id)
      ),
    }))
    .filter(specialCostGuest => specialCostGuest.guests.length > 0);
