import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { AddButton } from './AddButton';
import { Space } from './Space';
import { TextInput } from './TextInput';

interface AddEntityProps {
  label: string;
  onAdd: (input: string) => void;
  placeholder: string;
}

const StyledAddEntity = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const AddEntity: React.FC<AddEntityProps> = ({
  onAdd,
  label,
  placeholder,
}) => {
  const [name, setName] = useState('');
  const onAddEntity = () => {
    onAdd(name);
    setName('');
  };

  const disabled = name === '';

  const onSubmit = () => {
    if (!disabled) {
      onAddEntity();
    }
  };

  return (
    <StyledAddEntity onSubmit={onSubmit}>
      <TextInput label={placeholder} value={name} onChange={setName} />
      <Space />
      <AddButton disabled={disabled} label={label} onClick={onSubmit} />
    </StyledAddEntity>
  );
};
