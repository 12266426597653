import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'redux';
import { Loader } from 'semantic-ui-react';
import { getUid } from './auth/authReducer';
import { Database, DatabaseState } from './Database';
import { setInitial as setInitialFriendGroups } from './features/friendGroups/friendGroupsActions';
import { getFriendGroupsState } from './features/friendGroups/friendGroupsReducer';
import { setInitial as setInitialFriends } from './features/friends/friendsActions';
import { getFriendsState } from './features/friends/friendsReducer';
import { setInitial as setInitialRecipes } from './features/recipes/RecipesActions';
import { getRecipesState } from './features/recipes/RecipesReducer';
import { setInitial as setInitialSpecialCosts } from './features/specialCosts/specialCostsActions';
import { getSpecialCostsState } from './features/specialCosts/specialCostsReducer';
import { setInitial as setInitialVisits } from './features/visits/visitsActions';
import { getVisitsState } from './features/visits/visitsReducer';
import { Main } from './Main';

interface AppWithDatabaseProps {
  store: Store;
}

const LoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AppWithDatabase: React.FC<AppWithDatabaseProps> = ({ store }) => {
  const uid = useSelector(getUid);
  const [database, setDatabase] = useState<Database | null>(null);
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  useEffect(() => {
    setDatabase(new Database(uid));
  }, [uid]);

  useEffect(() => {
    if (database !== null && initialDataFetched) {
      store.subscribe(() => {
        const state = store.getState();
        database
          .storeData({
            friends: getFriendsState(state),
            friendGroups: getFriendGroupsState(state),
            specialCosts: getSpecialCostsState(state),
            recipes: getRecipesState(state),
            visits: getVisitsState(state),
          })
          .then(result => {})
          .catch(error => {
            console.log('---', error, '--- error');
          });
      });
    }
  }, [database, initialDataFetched, store]);

  useEffect(() => {
    if (database !== null) {
      const databasePromise = database.readData();
      databasePromise.then((data: DatabaseState | null) => {
        if (data) {
          if (data.visits) {
            store.dispatch(setInitialVisits(data.visits));
          }
          if (data.recipes) {
            store.dispatch(setInitialRecipes(data.recipes));
          }
          if (data.friends) {
            store.dispatch(setInitialFriends(data.friends));
          }
          if (data.friendGroups) {
            store.dispatch(setInitialFriendGroups(data.friendGroups));
          }
          if (data.specialCosts) {
            store.dispatch(setInitialSpecialCosts(data.specialCosts));
          }
        }
        setInitialDataFetched(true);
      });
    }
  }, [database, store]);

  return initialDataFetched ? (
    <Main />
  ) : (
    <LoadingWrapper>
      <Loader active inline />
    </LoadingWrapper>
  );
};
