import styled from '@emotion/styled';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Space } from '../../../../common/components/Space';
import { Friend } from '../../../../common/types/Friend';
import { AddRecipe } from '../../../recipes/components/AddRecipe';
import { EditingRecipeListItem, ListItem } from './EditingRecipeListItem';

export interface EditingRecipeListProps {
  guests: Friend[];
  onAdd: (id: string) => void;
  items: ListItem[];
  onRemove: (id: string) => void;
  visitId: string;
}

const StyledEditingList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EditingRecipeList: React.FC<EditingRecipeListProps> = ({
  guests,
  items,
  onAdd,
  onRemove,
  visitId,
}) => {
  return (
    <StyledEditingContainer>
      <StyledEditingList>
        {orderBy(items, ['recipe.name'], ['asc']).map((item, index) => {
          return (
            <EditingRecipeListItem
              guests={guests}
              key={item.recipe.id}
              index={index}
              item={item}
              onAdd={onAdd}
              onRemove={onRemove}
              visitId={visitId}
            />
          );
        })}
        <Space />
        <AddRecipe
          onRecipeAdded={(id: string) => {
            onAdd(id);
          }}
        />
      </StyledEditingList>
    </StyledEditingContainer>
  );
};
