import { Recipe } from '../../common/types/Recipe';
import { RecipeActions } from './RecipesActions';

type RecipeReducerState = Recipe;

export const recipeReducer = (
  state: RecipeReducerState,
  action: RecipeActions
): RecipeReducerState => {
  switch (action.type) {
    case 'RECIPES:EDIT_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'RECIPES:EDIT_TIME':
      return {
        ...state,
        time: action.time,
      };
    case 'RECIPES:EDIT_TYPE':
      return {
        ...state,
        type: action.recipeType,
      };
    case 'RECIPES:EDIT_ABOUT':
      return {
        ...state,
        about: action.about,
      };
    case 'RECIPES:EDIT_INSTRUCTIONS':
      return {
        ...state,
        instructions: action.instructions,
      };
    case 'RECIPES:EDIT_LINK':
      return {
        ...state,
        link: action.link,
      };
    case 'RECIPES:EDIT_INGREDIENT':
      return {
        ...state,
        ingredients: [
          ...state.ingredients.slice(0, action.index),
          action.ingredient,
          ...state.ingredients.slice(action.index + 1),
        ],
      };
    case 'RECIPES:REMOVE_INGREDIENT':
      return {
        ...state,
        ingredients: [
          ...state.ingredients.slice(0, action.index),
          ...state.ingredients.slice(action.index + 1),
        ],
      };
    case 'RECIPES:ADD_INGREDIENT':
      return {
        ...state,
        ingredients: [...state.ingredients, action.ingredient],
      };

    case 'RECIPES:ADD_SPECIAL_COST':
      return {
        ...state,
        specialCostIds: [...state.specialCostIds, action.specialCostId],
      };
    case 'RECIPES:REMOVE_SPECIAL_COST':
      return {
        ...state,
        specialCostIds: state.specialCostIds.filter(
          specialCostId => specialCostId !== action.specialCostId
        ),
      };
    case 'RECIPES:EDIT_PORTIONS':
      return {
        ...state,
        portions: action.portions,
      };
    default:
      return state;
  }
};
