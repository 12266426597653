import { FriendGroup } from '../../common/types/FriendGroup';
import { FriendGroupsActions } from './friendGroupsActions';

export type FriendGroupReducerState = FriendGroup;

export const friendGroupReducer = (
  state: FriendGroupReducerState,
  action: FriendGroupsActions
): FriendGroupReducerState => {
  switch (action.type) {
    case 'EDIT_FRIEND_GROUP_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'EDIT_FRIEND_GROUP_NOTE':
      return {
        ...state,
        note: action.note,
      };
    default:
      return state;
  }
};
