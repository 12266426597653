import * as React from 'react';
import { useSelector } from 'react-redux';
import { EditableFriendGroupList } from '../../../common/components/EditableFriendGroupList';
import { FriendGroup } from '../../../common/types/FriendGroup';
import { getFriendGroupList } from '../../friendGroups/friendGroupsReducer';

interface FriendGroupsProps {
  addFriendGroup: (guestId: string) => void;
  editing: boolean;
  friendGroups: Array<FriendGroup | undefined>;
  removeFriendGroup: (guestId: string) => void;
}

export const FriendGroups: React.FC<FriendGroupsProps> = ({
  addFriendGroup,
  editing,
  friendGroups,
  removeFriendGroup,
}) => {
  const allFriendGroups = useSelector(getFriendGroupList);

  const items = allFriendGroups.map((allFriendGroup, index) => {
    const checked = !!friendGroups.find(guest => {
      return guest !== undefined && guest.id === allFriendGroup.id;
    });
    return {
      checked,
      id: allFriendGroup.id,
      label: allFriendGroup.name,
    };
  });

  return (
    <EditableFriendGroupList
      editing={editing}
      onAdd={addFriendGroup}
      items={items}
      onRemove={removeFriendGroup}
    />
  );
};
