import styled from '@emotion/styled';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Icon, Input as SemanticInput } from 'semantic-ui-react';
import uuid from 'uuid';
import { addVisit } from '../visits/visitsActions';

export interface AddVisitCellProps {}

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
`;

export const AddVisitCell: React.FC<AddVisitCellProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');

  const onAddVisit = () => {
    const id = uuid.v4();
    dispatch(addVisit({ name }, id));
    history.push('/visit/' + id + '?editing=true');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <Card onClick={onAddVisit} centered={true}>
      <StyledIcon>
        <Icon disabled={!name} name={'plus circle'} size={'massive'} />
      </StyledIcon>
      <Card.Content>
        <Card.Header>{'New visit'}</Card.Header>
        <Card.Description>
          <div onClick={e => e.stopPropagation()}>
            <SemanticInput
              placeholder={'Visit name'}
              onChange={onInputChange}
              value={name}
              fluid={true}
            />
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
