import * as React from 'react';
import { TextArea } from '../../../common/components/TextArea';

interface NoteProps {
  edit: (input: string) => void;
  note?: string;
}

export const Note: React.FC<NoteProps> = ({ edit, note }) => {
  return <TextArea value={note} onChange={edit} label="Note" />;
};
