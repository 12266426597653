import * as React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { addFriendGroup } from '../friendGroupsActions';

interface AddFriendGroupProps {
  onFriendGroupAdded?: (id: string) => void;
}

export const AddFriendGroup: React.FC<AddFriendGroupProps> = ({
  onFriendGroupAdded,
}) => {
  const dispatch = useDispatch();
  const onAddFriendGroup = (name: string) => {
    const id = uuid.v4();
    dispatch(addFriendGroup({ name }, id));
    if (onFriendGroupAdded) {
      onFriendGroupAdded(id);
    }
  };

  return (
    <AddEntity
      label={'New group'}
      onAdd={onAddFriendGroup}
      placeholder={'Group name'}
    />
  );
};
