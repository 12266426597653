import { Omit } from 'react-router';
import uuid from 'uuid';
import { SpecialCost } from '../../common/types/SpecialCost';
import { SpecialCostsReducerState } from './specialCostsReducer';

export interface SetInitialAction {
  payload: {
    initial: SpecialCostsReducerState;
  };
  type: 'SPECIAL_COSTS:SET_INITIAL';
}

export const setInitial = (
  initial: SpecialCostsReducerState
): SetInitialAction => ({
  payload: {
    initial,
  },
  type: 'SPECIAL_COSTS:SET_INITIAL',
});

interface AddSpecialCostAction {
  specialCost: SpecialCost;
  type: 'SPECIAL_COSTS:ADD_SPECIAL_COST';
}

export const addSpecialCost = (
  specialCost: Omit<SpecialCost, 'id'>,
  id = uuid.v4()
): AddSpecialCostAction => ({
  type: 'SPECIAL_COSTS:ADD_SPECIAL_COST',
  specialCost: {
    ...specialCost,
    id,
  },
});

interface RemoveSpecialCostAction {
  id: string;
  type: 'SPECIAL_COSTS:REMOVE_SPECIAL_COST';
}

export const removeSpecialCost = (id: string): RemoveSpecialCostAction => ({
  type: 'SPECIAL_COSTS:REMOVE_SPECIAL_COST',
  id,
});

export interface EditNameAction {
  specialCostId: string;
  name: string;
  type: 'SPECIAL_COSTS:EDIT_SPECIAL_COST_NAME';
}

export const editName = (
  specialCostId: string,
  name: string
): EditNameAction => ({
  specialCostId,
  name,
  type: 'SPECIAL_COSTS:EDIT_SPECIAL_COST_NAME',
});

export type SpecialCostsActions =
  | AddSpecialCostAction
  | RemoveSpecialCostAction
  | EditNameAction
  | SetInitialAction;
