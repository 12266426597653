import * as React from 'react';
import { TextInput } from '../../../common/components/TextInput';

interface TimeProps {
  edit: (text: string) => void;
  time?: string;
}

export const Time: React.FC<TimeProps> = ({ edit, time }) => {
  return <TextInput value={time} onChange={edit} label="Time" />;
};
