import styled from '@emotion/styled';
import { format, isValid, parse } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Segment } from 'semantic-ui-react';

registerLocale('en-GB', enGB);

interface DatePickerProps {
  date?: string;
  edit: (text: string) => void;
}

const StyledSegment = styled(Segment)`
  cursor: pointer;
`;

class ExampleCustomInput extends React.Component<{
  value?: string;
  onClick?: () => void;
}> {
  render() {
    let { value, onClick } = this.props;
    return (
      <StyledSegment as={'button'} onClick={onClick}>
        {value}
      </StyledSegment>
    );
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({ edit, date }) => {
  const handleChange = (date: Date | null) => {
    if (date) {
      edit(format(date, 'yyyy-MM-dd'));
    } else {
      edit('');
    }
  };
  const dateDate = date ? parse(date, 'yyyy-MM-dd', new Date()) : undefined;

  return (
    <ReactDatePicker
      customInput={<ExampleCustomInput />}
      dateFormat={'yyyy-MM-dd'}
      locale="en-GB"
      onChange={handleChange}
      selected={isValid(dateDate) ? dateDate : undefined}
    />
  );
};
