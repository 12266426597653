import * as React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';
import { AddEntity } from '../../../common/components/AddTextEntity';
import { addFriend } from '../friendsActions';

interface AddFriendProps {
  onFriendAdded?: (id: string) => void;
}

export const AddFriend: React.FC<AddFriendProps> = ({ onFriendAdded }) => {
  const dispatch = useDispatch();
  const onAddFriend = (name: string) => {
    const id = uuid.v4();
    dispatch(addFriend({ name }, id));
    if (onFriendAdded) {
      onFriendAdded(id);
    }
  };

  return (
    <AddEntity
      label={'New friend'}
      onAdd={onAddFriend}
      placeholder={'Friend name'}
    />
  );
};
