import * as React from 'react';
import { ReactNode } from 'react';

export interface WrapProps {
  condition: boolean;
  wrap: (children: ReactNode) => ReactNode;
}

export const Wrap: React.FC<WrapProps> = ({ children, condition, wrap }) =>
  condition ? <>{wrap(children)}</> : <>{children}</>;
