import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CardGroup, Header } from 'semantic-ui-react';
import { compareDates } from '../visits/utils/compareDates';
import { getVisitsList } from '../visits/visitsReducer';
import { AddVisitCell } from './AddVisitCell';
import { VisitCell } from './VisitCell';

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const visits = useSelector(getVisitsList);

  return visits.length > 0 ? (
    <CardGroup itemsPerRow={4} stackable={true}>
      {visits
        .concat()
        .sort((a, b) => {
          return compareDates(a.date, b.date);
        })
        .map(visit => (
          <VisitCell key={visit.id} visit={visit} />
        ))}
      <AddVisitCell />
    </CardGroup>
  ) : (
    <div>
      <Header>Welcome to the visit book!</Header>
      Let`s get started by adding visits <Link to={'/visits'}>here</Link>,{' '}
      friends <Link to={'/friends'}>here</Link> or new recipes{' '}
      <Link to={'/recipes'}>here</Link>!
    </div>
  );
};
